import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';

// Images
import ArrowDownIcon from "images/arrow-down.svg";
import { userRoles } from 'constants';
import { setSelectedRole } from "slices/app/reducer";

const selectStyles = {
    control: (provided) => ({
        ...provided,
        cursor: "pointer",
        backgroundColor: "#0B1723",
        color: "white",
        border: "1px solid #382F5C",
        outline: "none",
        borderRadius: "12px",

        ":hover": {
            border: "1px solid #666699",
            outline: "none",
            backgroundColor: "#7357eb",
        },

        ":focus": {
            border: "1px solid #666699",
            outline: "none",
        },

        ":active": {
            border: "1px solid #666699",
            outline: "none",
        },

    }),
    menu: (provided) => ({
        ...provided,
        color: "white",
        borderRadius: 10,
        overflow: "hidden",
        boxShadow: "0px 0px 4px 0px #7357EB40",
        backgroundColor: "#0B1723",
    }),
    option: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#0B1723",
        textAlign: "left",
        cursor: "pointer",
        fontSize: "0.875rem",
        ":hover": {
            backgroundColor: "#132639",
        }
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "#beef9e",
        fontSize: "0.875rem",
        padding: 0,
    }),
    input: (baseStyles) => ({
        ...baseStyles,
        color: "white",
        padding: 0,
    }),
    valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: "0 2px",
    }) 
};

const Control = ({ children, ...props }) => (
    <components.Control {...props} className="py[6px] px-3">
        <span className="text-sm text-white">I'm Accountable for</span>
        {children}
    </components.Control>
);

const RoleSelect = ({ onChange }) => {

    const dispatch = useDispatch();
    const { selectedRole } = useSelector(state => state.AppReducer);

    const onRoleSelect = (selectedData) => {
        dispatch(setSelectedRole(selectedData));
        if (typeof onChange === "function") {
            onChange(selectedData);
        }
    }

    return (
        <Select
            options={userRoles}
            value={selectedRole}
            onChange={onRoleSelect}
            backspaceRemovesValue={false}
            components={{
                Control,
                DropdownIndicator: () => <img src={ArrowDownIcon} />,
                IndicatorSeparator: null
            }}
            isClearable={false}
            isSearchable={false}
            hideSelectedOptions={false}
            styles={selectStyles}
            tabSelectsValue={false}
        />
    )
}

export default RoleSelect;