import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setAddDashboardVisibility, setSelectedDashboard } from "slices/dashboard/reducer";
import Select, { components } from 'react-select';

// Images
import ArrowDownIcon from "images/arrow-down.svg";
import AddSquareIcon from "images/add-square-green-icon.svg";

const selectStyles = {
    control: (provided) => ({
        ...provided,
        cursor: "pointer",
        backgroundColor: "#0B1723",
        color: "white",
        border: "1px solid #382F5C",
        padding: "6px 12px 6px 4px",
        outline: "none",
        borderRadius: "12px",
        width: "150px",

        ":hover": {
            border: "1px solid #666699",
            outline: "none",
        },

        ":focus": {
            border: "1px solid #666699",
            outline: "none",
        },

        ":active": {
            border: "1px solid #666699",
            outline: "none",
        },

    }),
    menu: (provided) => ({
        ...provided,
        color: "white",
        borderRadius: 10,
        overflow: "hidden",
        boxShadow: "0px 0px 4px 0px #7357EB40",
        backgroundColor: "#0B1723",
        width: "15rem",
    }),
    option: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#0B1723",
        textAlign: "left",
        cursor: "pointer",
        fontSize: "0.875rem",
        ":hover": {
            backgroundColor: "#132639",
        }
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "white",
        fontSize: "0.875rem",
    }),
    input: (baseStyles) => ({
        ...baseStyles,
        color: "white",
    })
};

const MenuList = (props) => {
    const dispatch = useDispatch();

    const { setMenuIsOpen } = props.selectProps;

    const handleDashboardAddClick = () => {
        setMenuIsOpen(false);
        dispatch(setAddDashboardVisibility(true));
    }
    return (
        <components.MenuList  {...props}>
            <button
                onClick={handleDashboardAddClick}
                className="w-full text-sm flex justify-center items-center gap-2 px-3 py-2 hover:bg-primary-light"
            >
                <span className="text-secondary-1 text-nowrap">Create new dashboard</span>
                <img src={AddSquareIcon} alt="Add Dashboard" />
            </button>
            {props.children}
        </components.MenuList>
    );
};

const selectAll = {
    label: "All dashboards",
    value: null,
    id: null,
}

const DashBoardSelect = ({ onChange }) => {

    const dispatch = useDispatch();
    const { selectedDashboard, dashboards } = useSelector(state => state.DashboardReducer);

    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const onDashboardSelect = (selectedData) => {
        dispatch(setSelectedDashboard(selectedData));
        if (typeof onChange === "function") {
            onChange(selectedData);
        }
    }

    const options = dashboards.map(dashboard => ({ ...dashboard, value: dashboard?.id, label: dashboard?.name }));


    return (
        <Select
            options={[selectAll, ...options]}
            value={selectedDashboard}
            onChange={onDashboardSelect}
            backspaceRemovesValue={false}
            components={{
                MenuList,
                DropdownIndicator: () => <img src={ArrowDownIcon} />,
                IndicatorSeparator: null
            }}
            menuIsOpen={menuIsOpen}
            setMenuIsOpen={setMenuIsOpen}
            onMenuOpen={() => setMenuIsOpen(true)} // Handle menu opening
            onMenuClose={() => setMenuIsOpen(false)} // Handle menu closing
            isClearable={false}
            isSearchable={false}
            hideSelectedOptions={false}
            styles={selectStyles}
            tabSelectsValue={false}
        />
    )
}

export default DashBoardSelect;