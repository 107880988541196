import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedRole: {
        label: "Business growth (CEO)",
        value: "business-growth-ceo"
    },
    errors: {}
}

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setSelectedRole: (state, action) => {
            const payload = action.payload;
            state.selectedRole = payload
        }
    },
});

export const { setSelectedRole } = appSlice.actions;

export default appSlice.reducer;