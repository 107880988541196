import { combineReducers } from "redux";

// Reducers
import chatSliceReducer from "slices/chat/reducer";
import alertSliceReducer from "slices/alerts/reducer";
import dashboardSliceReducer from "slices/dashboard/reducer";
import collabrationSliceReducer from "slices/collabration/reducer";
import usersSliceReducer from "slices/users/reducer";
import appSliceReducer from "slices/app/reducer";

const rootReducer = combineReducers({
    ChatReducer: chatSliceReducer,
    AlertReducer: alertSliceReducer,
    DashboardReducer: dashboardSliceReducer,
    CollabrationReducer: collabrationSliceReducer,
    UsersReducer: usersSliceReducer,
    AppReducer: appSliceReducer
});

export default rootReducer;