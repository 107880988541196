import { useEffect, useState } from 'react';
import { getUniqueQuestionList } from 'api/history/index';

export const useUniqueQuestionList = (userId) => {
    const [questionList, setQuestionList] = useState([]);

    const fetchQuestionsList = async () => {
        try {
            const payload = {
                userId
            }

            const response = await getUniqueQuestionList(payload);
            const filteredQuestionList = response.filter(x => !!x?.df_data);
            const sortedData = filteredQuestionList.sort((a, b) => b.query_id - a.query_id)
            const transformedQuestionList = sortedData.map(question => {
                return {
                    label: question.question,
                    value: question.query_id,
                    columns: Object.keys(JSON.parse(question.df_data)[0]).map(x => {
                        return {
                            label: x,
                            value: x
                        }
                    })
                }
            })
            setQuestionList(transformedQuestionList);
        } catch (err) {
            console.log("Error: ", err);
        }
    }

    useEffect(() => {
        if (userId) {
            fetchQuestionsList();
        }
    }, [userId]);

    return questionList;
}