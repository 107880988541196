import { toast } from "react-toastify";

const toastOptions = {
  className: "text-black",
  bodyClassName: "text-sm",
  autoClose: 3000,
  position: "top-right",
  // Ensure new toasts appear on top
  updateId: "unique-toast",  // Using a constant ID ensures only one toast at a time
};

export const notifySuccess = (message = "Successful") => {
  // Using updateId will replace any existing toast with this ID
  return toast.success(message, {
    ...toastOptions,
    toastId: "unique-toast"
  });
};

export const notifyError = (message = "Failed") => {
  return toast.error(message, {
    ...toastOptions,
    toastId: "unique-toast"
  });
};

export const notifyWarning = (message = "Warning") => {
  return toast.warning(message, {
    ...toastOptions,
    toastId: "unique-toast"
  });
};

export const notifyInfo = (message = "Info") => {
  return toast.info(message, {
    ...toastOptions,
    toastId: "unique-toast"
  });
};