import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from 'images/main-logo.svg';
import eyeOpen from 'images/eye.svg';
import eyeClosed from 'images/eye-closed.svg';
import { useAuth } from 'context/AuthContext';
import {notifyError} from "utils/customToasts";

const ForgetPasswordComponent = () => {
  const [email, setEmail] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false)

  const isFormValid = () => {
    return email;
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return notifyError("Please enter valid email address");
    }

    setIsEmailSent(true);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#0D1B2A]">
      <div className={`${isEmailSent ? "" : "bg-[#0B1723]"} text-white p-8 rounded-lg w-full max-w-md`}>
        <img src={logo} alt="Storymetrix Logo" className="h-16 mx-auto mb-8" />

        {
          isEmailSent ? (
            <>
              <h2 className={`text-2xl mt-52 font-bold text-center mb-4 transition-opacity duration-300 ease-out`}>
                A Email has been sent!
              </h2>

              <p className={`text-center mb-6 transition-opacity duration-300 ease-out`}>
                By clicking on the link and you will be able to enter a new password
              </p>
            </>
          ) : (
            <>
              <h2 className={`text-2xl font-bold text-center mb-4 transition-opacity duration-300 ease-out`}>
                Forgot password
              </h2>

              <p className={`text-center mb-6 transition-opacity duration-300 ease-out`}>
                A link will be sent to your email ID to reset your password.
              </p>

              <div className={`transition-opacity duration-300 ease-out`}>
                <div className="mb-4">
                  <label htmlFor="email" className="block mb-2 text-sm">Email address</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-3 rounded bg-[#0D1B2A] border border-[#382F5C]"
                    placeholder="Enter your Email Id"
                  />
                </div>

                <button
                  className={`w-full p-3 mt-4 rounded transform transition-transform duration-300 ease-out ${isFormValid() ? 'bg-purple-700 hover:bg-purple-800' : 'bg-gray-600 cursor-not-allowed'}`}
                  disabled={!isFormValid()}
                  onClick={handleButtonClick}
                >
                  Reset password
                </button>
              </div>
              <div className={`text-center mt-4 transition-opacity duration-300 ease-out`}>
                Already have an account?{' '}
                <Link to="/" className="text-purple-500 cursor-pointer">Sign In</Link>
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

export default ForgetPasswordComponent;