import { Routes, Route } from 'react-router-dom';

import { publicRoutes, protectedRoutes } from './allRoutes';

// Components
import ProtectedRoute from 'components/ProtectedRoute';
import { AuthProvider } from 'context/AuthContext';

function AppRoutes() {
    return (
        <AuthProvider>
            <Routes>
                {/* Public Routes */}
                {
                    publicRoutes.map(route => {
                        return (
                            <Route key={route.name} path={route.path} element={route.element} />
                        )
                    })
                }

                {/* Protected Routes */}
                {
                    protectedRoutes.map(route => {
                        return (
                            <Route key={route.name} path={route.path} element={<ProtectedRoute>{route.element}</ProtectedRoute>} />
                        )
                    })
                }
            </Routes>
        </AuthProvider>
    );
}

export default AppRoutes;