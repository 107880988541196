export const userRoles = [
    {
        label: "Business growth (CEO)",
        value: "business-growth-ceo"
    },
    {
        label: "Customer Experience",
        value: "customer-experience"
    },
    {
        label: "Finance",
        value: "finance"
    },
    {
        label: "Marketing and sales",
        value: "marketing-and-sales"
    },
    {
        label: "Operations",
        value: "operations"
    }
]

export const avatarBGColors = [
    "bg-avatar-orange",
    "bg-avatar-yellow",
    "bg-avatar-blue",
    "bg-avatar-purple",
    "bg-avatar-green",
]