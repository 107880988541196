import React from 'react';
import Plot from 'react-plotly.js';

const ForeCastChart = ({ data, selectedOption }) => {

  const type = selectedOption?.forecastType || ""
  const chartTitle = selectedOption?.label || "Forecast"

  const {
    actual_vs_predicted = {},
    simulation = {},
    forecast = {},
    historical_data = {},
    mean_forecast = {},
    forecast_confidence_interval = {},
    simulated_paths = [],
    forecast_dates = [],
    base_forecast = [],
    scenarios = []
  } = data;

  const colors = {
    blue: "#58adf7",
    purple: "#534fbe",
    green: "#67df7e",
  }

  const DesChartData = [
    {
      x: simulation?.dates || [],
      y: simulation?.values || [],
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Simulation',
      line: { color: colors.blue }
    }
  ];

  const ArimaChartData = [
    {
      x: actual_vs_predicted?.x || [],
      y: actual_vs_predicted?.actual || [],
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Actual',
      line: { color: colors.blue }
    },
    {
      x: actual_vs_predicted?.x || [],
      y: actual_vs_predicted?.predicted || [],
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Predicted',
      line: { color: colors.purple }
    },
    {
      x: forecast?.dates || [],
      y: forecast?.values || [],
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Forecast',
      line: { color: colors.green , dash: 'dot' } // Dotted line to differentiate forecast
    }
  ];

  const McmcChartData = [
    {
      x: historical_data?.dates || [],
      y: historical_data?.values || [],
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Historical Data',
      line: { color: colors.blue },
    },
    {
      x: mean_forecast?.dates || [],
      y: mean_forecast?.values || [],
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Mean Forecast',
      line: { color: colors.green },
    },
    {
      x: mean_forecast?.dates || [],
      y: forecast_confidence_interval?.lower_bound || [],
      type: 'scatter',
      mode: 'lines',
      name: 'Lower Bound',
      fill: 'tonexty',
      line: { color: colors.green }, 
    },
    {
      x: mean_forecast?.dates || [],
      y: forecast_confidence_interval?.upper_bound || [],
      type: 'scatter',
      mode: 'lines',
      name: 'Upper Bound',
      fill: 'tonexty',
      line: { color: colors.green }, 
    },
  ];

  const MonteCarloSimulation = [
    {
      x: historical_data?.dates || [],
      y: historical_data?.values || [],
      mode: 'lines+markers',
      name: 'Historical Data',
      line: { color: colors.blue },
    },
    {
      x: forecast?.[0]?.dates || [],
      y: forecast?.[0]?.values || [],
      mode: 'lines+markers',
      name: 'Forecast',
      line: { color: colors.purple },
    },
    ...(simulated_paths?.paths?.map((path, index) => {
      return {
        x: simulated_paths?.dates || [],
        y: path?.values || [],
        mode: 'lines+markers',
        name: `Simulation ${index + 1}`,
        line: { color: colors.green },
      }
    }) || [])
  ]

  const ScenarioAnalysisData = [
    {
      x: forecast_dates,
      y: base_forecast,
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Base Forecast',
      line: { color: colors.blue },
    },

    ...(scenarios?.map(scenario => {
      return {
        x: forecast_dates,
        y: scenario?.forecast || [],
        type: 'scatter',
        mode: 'lines+markers',
        name: scenario.name,
        line: { color: colors.green },
      }
    }) || [])
  ];

  const chartDataObject = {
    ARIMA: ArimaChartData,
    DES: DesChartData,
    MCMC: McmcChartData,
    "Monte Carlo": MonteCarloSimulation,
    "Scenario Analysis": ScenarioAnalysisData,
  }

  return (
    <div className="p-2">
      <Plot
        className=" text-white"
        data={chartDataObject[type]}
        config={{
          displayModeBar: false,
        }}
        style={{
          color: "white"
        }}
        layout={{
          title: {
            text: chartTitle,
            font: {
              size: 24
            }
          },
          xaxis: { title: 'Date', showgrid: false, },
          yaxis: { title: 'Values' },
          // legend: { orientation: 'v', x: 0.5, xanchor: 'end' },
          showlegend: true,
          legend: {
            x: 1,
            y: 1,
          },
          plot_bgcolor: "transparent",
          paper_bgcolor: "transparent",
          font: {
            color: "white"
          },
          datarevision: type
        }}
      />
    </div>
  );
};

export default ForeCastChart;