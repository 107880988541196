import * as yup from "yup";

export const DownvoteSchema = yup.object().shape({
    query_id: yup.number().required("Please select a query"),
    reason: yup.string().required("Please select a reason"),
    comment: yup.string().required("Comment is Required"),
    options: yup.array().of(yup.string()).when("reason", {
        is: val => val === "others",
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.required("Please Select at least one option")
            // .min(1, "minimum 1 options is required"),
    }),
});