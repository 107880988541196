import Modal from "components/Modal";
import { useEffect, useState } from 'react';
import loadingGif from 'images/loader2.webp';
import { useDispatch, useSelector } from "react-redux";
import { setGraphModalVisibility, setSelectedGraph } from 'slices/dashboard/reducer';
import { CreateDashboardSchema } from '../../validations/dashboard';
import { createDashboard } from "api/dashboard";

// Images
import CrossIcon from "images/cross-icon.svg";
import HighchartsComponentAlt from "components/HighchartsComponentAlt";
import styles from 'styles/dashboardGraphPopup.module.css';
import DOMPurify from 'dompurify';

const GraphPopup = () => {

    const dispatch = useDispatch();
    const { graphModalVisibility, selectedGraph } = useSelector(state => state.DashboardReducer);

    const handleGraphClose = () => {
        dispatch(setSelectedGraph(null));
        dispatch(setGraphModalVisibility(false));
    }

    return (
        <Modal
            position="top-right"
            className="relative w-full max-w-[60rem] py-10 px-8 bg-primary-3 text-white rounded-lg text-left"
            open={graphModalVisibility}
        >
            <img
                onClick={handleGraphClose}
                className="absolute cursor-pointer h-6 w-6 -top-3 -right-3"
                alt={"Close Forecast"}
                src={CrossIcon}
            />

            <div className="flex flex-col gap-9">
                <div className="flex flex-col gap-2 p-4 border border-[#BEEF9E66] rounded-lg ">

                    <div className={`flex flex-col gap-2 `}>
                        <h2 className="font-medium text-sm">{selectedGraph?.dashboard_name || "Dashboard Chart"}</h2>
                        <p className="text-xs text-[#DADCDE]">
                            Created on {selectedGraph?.createdAtDate} at {selectedGraph?.createdAtTime}
                        </p>
                    </div>

                    <hr className="border-[#BEEF9E66]" />

                    {
                        graphModalVisibility && selectedGraph && (
                            <HighchartsComponentAlt
                                chartTypeOuter={selectedGraph?.graph_type}
                                chartTitle={selectedGraph?.graph_head}
                                data={selectedGraph?.table}
                                dataToPlot={selectedGraph?.highchart_code}
                            />
                        )
                    }
                </div>

                <div>
                    <h3 className="font-medium text-base">Recommended actions:</h3>

                    {selectedGraph && (
                        <div className={`mt-5 overflow-scroll text-xs p-5 bg-primary ${styles.recommended_actions}`}>
                            {/* {question} */}
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedGraph?.actions) }} />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
}

export default GraphPopup