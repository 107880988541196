import React, { useState } from "react";
import { AlertsContext } from "components/Alerts/AlertsContext";

const AlertsProvider = ({ children }) => {
    const [showModal, setShowModal] = useState(false);

    const contextValue = {
        showModal,
        setShowModal,
    };

    return <AlertsContext.Provider value={contextValue}>{children}</AlertsContext.Provider>;
};

export default AlertsProvider;