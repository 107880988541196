import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const Axios = axios.create({
  baseURL,
});

// Add request interceptor to include token in the headers
Axios.interceptors.request.use(
  (request) => {
    // Get the token from localStorage
    const token = localStorage.getItem("authToken");

    // If token exists, add it to the Authorization header
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }

    // Always return the request to continue the request cycle
    return request;
  },
  (err) => {
    // If any error occurs in the request, reject the promise
    return Promise.reject(err);
  }
);

// Handle responses
Axios.interceptors.response.use(
  (response) => {
    // Return the response as is
    return response;
  },
  (error) => {
    // Handle errors here (e.g., token expiration, network error, etc.)
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access (token expired or invalid)
      console.log("Unauthorized access. Please login again.");
    }

    // Reject the promise with the error object
    return Promise.reject(error);
  }
);

export default Axios;
