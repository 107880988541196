import styles from "styles/dialogue.module.css";
import ResetPasswordComponent from "components/auth/ResetPassword";

const ResetPasswordPage = () => { 
  return (
    <div className={styles.newDialog}>
      <ResetPasswordComponent />     
    </div>
  );
};

export default ResetPasswordPage;
