import ChatIcon from 'images/chat-alt.svg';
import SubmitIcon from 'images/submit-icon.svg';
import { useState } from 'react';
import CommentList from "components/Collabration/CommentList";
import { addCommentToForum } from 'api/collaborativeForum/index';
import { notifyError } from 'utils/customToasts';

const LeftSideContainer = ({ data, forumId, onAddComment }) => {

    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);

    const addComment = async () => {
        try {
            setLoading(true);

            // prepare payload
            const payload = {
                forum_id: parseInt(forumId),
                user_id: 1,
                comment: comment
            }

            // Make API call to add comment
            const response = await addCommentToForum(payload);

            // Handle Error
            if (!response?.comment_id) {
                notifyError(response?.message || "Failed to add comment");
                return;
            }

            // Trigger callback
            onAddComment(comment);

            // Reset to initial states
            setComment("");
            setLoading(false);
        } catch (err) {
            notifyError(err?.message || "Failed to add comment");
            setLoading(false);
        }
    }

    const handleEnterSubmit = async (e) => {
        if ((e.key === "Enter" || e.keyCode === 13) && e.target.value) {
            e.preventDefault();
            e.stopPropagation();
            await addComment();
        }
    }

    const disableCommentSubmit = !comment?.trim() || loading

    return (
        <div className="bg-primary-3 p-3 col-span-3 rounded-xl">
            <div className="flex justify-between items-center">
                <h2 className="grow font-medium text-lg">Questions</h2>
                <div className="flex gap-3 items-center">
                    <img className="h-5 w-5" src={ChatIcon} alt="Chat" />
                    {/* <img className="h-5 w-5" src={ChatIcon} alt="Chat" /> */}
                </div>
            </div>

            <hr className="mt-5 border-[#7357EB66]" />

            <div className="mt-4 p-4 flex flex-col gap-3 border border-[#7357EB66] rounded-lg">
                <div className="flex gap-2 items-center">

                    <div className="w-10 h-10 rounded-full bg-orange-500 flex items-center justify-center">
                        {data?.creator_name?.trim()?.[0]?.toUpperCase() || "U"}
                    </div>
                    <span>{data?.creator_name}</span>
                </div>

                <p className="flex gap-3 px-1">
                    <span>&#x2022;</span>
                    <span className="text-sm font-normal">{data?.question}</span>
                </p>
            </div>

            <h1 className="mt-4 text-base font-medium">Comments</h1>

            {/* Comment  */}
            <div className="mt-4 p-3 flex flex-col gap-3 border border-[#7357EB66] rounded-lg">

                <CommentList comments={data?.comments || []} />


                <div className="relative flex items-center">
                    <input
                        type="text"
                        value={comment}
                        onKeyDown={handleEnterSubmit}
                        onChange={(e) => setComment(e.target.value)}
                        className="mt-4 w-full p-3 pr-10 rounded bg-[#0D1B2A] border border-[#838383]"
                        placeholder="Reply..."
                    />

                    <button
                        disabled={disableCommentSubmit}
                        className={`${disableCommentSubmit ? "cursor-not-allowed" : "cursor-pointer"} absolute right-3 top-1/2`}
                        onClick={addComment}
                    >
                        <img className={`w-5 h-5 ${disableCommentSubmit ? "opacity-40" : ""}`} src={SubmitIcon} alt="Submit" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default LeftSideContainer;