import { createAlert, getAlertById, updateAlert as updateAlertApi } from "api/alerts";
import CreateAlert from "components/Base/createAlert";
import { useDispatch, useSelector } from 'react-redux';
import { setAddAlertVisibility, setSelectedGraph } from 'slices/dashboard/reducer';
import { CreateAlertSchema } from "validations/alerts";
import { CreateDashboardAlertSchema } from "validations/dashboard";
import { useAlertMethods } from 'hooks/alertHooks';
import { useEffect, useState } from "react";
import {notifySuccess, notifyError} from "utils/customToasts";

const CreateDashboardAlert = () => {

    const dispatch = useDispatch();
    const { addAlert, updateAlert } = useAlertMethods();
    const { addAlertVisibility, selectedGraph } = useSelector(state => state.DashboardReducer);
    const [formData, setFormData] = useState();

    const query = {
        label: selectedGraph?.question || "Unknown Question",
        value: selectedGraph?.chartid,
        columns: Object?.keys(selectedGraph?.table?.[0] || {})?.map(x => ({ label: x, value: x })) || []
    }

    const handleCloseAlert = () => {
        dispatch(setAddAlertVisibility(false));
        dispatch(setSelectedGraph(null));
    }

    const handleUpdateAlert = async (data) => {
        try {
            const payload = {
                ...data,
                alert_id: formData.alert_id,
            }
            delete payload.query_id;

            const response = await updateAlertApi(payload);

            updateAlert({
                chart_id: parseInt(payload?.chart_id),
                question: selectedGraph?.question,
                value_column: payload?.value_column,
                condition_type: payload?.condition,
                threshold: payload.threshold,
                email: payload.email,
                created_at: Date.now()
            });

            notifySuccess(response.message);
            handleCloseAlert();
        } catch (e) {
            notifyError("Error updating alert");
            return;
        }
    }

    const handleCreateAlert = async (data) => {
        try {
            const payload = { ...data }
            delete payload.query_id;

            const response = await createAlert(payload);

            addAlert({
                chart_id: parseInt(payload?.chart_id),
                question: selectedGraph?.question,
                value_column: payload?.value_column,
                condition_type: payload?.condition,
                threshold: payload.threshold,
                email: payload.email,
                created_at: Date.now()
            });

            notifySuccess(response.message);
            handleCloseAlert();
        } catch (e) {
            notifyError("Error creating alert");
            return;
        }
    }

    const handleSaveAlert = async (alert) => {
        if (formData?.alert_id) {
            handleUpdateAlert(alert);
        } else {
            handleCreateAlert(alert);
        }
    }

    const getAlertDetails = async () => {
        try {
            const payload = {
                alert_id: selectedGraph.alert_id,
            }

            const response = await getAlertById(payload);
            setFormData({
                alert_id: response.idalerts,
                query,
                condition: response.condition_type,
                column: response.value_column,
                threshold: response.threshold,
                email: response.email,
            });
        } catch (e) {
            notifyError("Failed to get alert details");
        }
    }

    useEffect(() => {
        if (selectedGraph?.alert_id) {
            getAlertDetails();
        } else {
            setFormData({ query });
        }
    }, [selectedGraph?.alert_id])

    return (
        <CreateAlert
            showModal={addAlertVisibility}
            title={selectedGraph?.alert_id ? "Edit alert" : "Create alert"}
            questions={query ? [query] : []}
            onClose={handleCloseAlert}
            onSubmit={handleSaveAlert}
            formData={formData}
            ValidationSchema={CreateDashboardAlertSchema}
        />
    )
}
export default CreateDashboardAlert;
