import { createAsyncThunk } from "@reduxjs/toolkit";
import { createDashboard, getDashboards, moveChartToDashboard } from 'api/dashboard';
import { addChartToDashboard, getDashboardCharts, deleteChartFromDashboard } from '../../api/dashboard/index';

// Add new dashboard
export const createDashboardThunk = createAsyncThunk(
    "createDashboard",
    async (payload) => {
        try {
            const response = await createDashboard(payload);
            return response;
        } catch (error) {
            return error;
        }
    }
)

// Get dashboards
export const getDashboardsThunk = createAsyncThunk(
    "getDashboards",
    async () => {
        try {
            const response = await getDashboards();
            return response;
        } catch (error) {
            return error;
        }
    }
)

// Add chart to dashboard
export const addChartToDashboardThunk = createAsyncThunk(
    "addChartToDashboard",
    async (payload) => {
        try {
            const response = await addChartToDashboard(payload);
            return response;
        } catch (error) {
            return error;
        }
    }
)

// Get dashboard chart list
export const getDashboardChartsThunk = createAsyncThunk(
    "getDashboardCharts",
    async (paylaod) => {
        try {
            const response = await getDashboardCharts(paylaod);
            return response;
        } catch (error) {
            return error;
        }
    }
)

// Delete chart from dashboard
export const deleteChartFromDashboardThunk = createAsyncThunk(
    "deleteChartFromDashboard",
    async (payload) => {
        try {
            const response = await deleteChartFromDashboard(payload);
            return response;
        } catch (error) {
            return error;
        }
    }
)

// Move chart from one dashboard to another
export const moveChartToDashboardThunk = createAsyncThunk(
    "moveChartToDashboard",
    async (payload) => {
        try {
            const response = await moveChartToDashboard(payload);
            return response;
        } catch (error) {
            return error;
        }
    }
)
