import React, { useEffect } from 'react';
import config from './config';

const SubscriptionChecker = ({ onSubscriptionCheck }) => {
    const apiUrl = config.apiUrl;
  const subscriptionCheckerUrl = apiUrl+"/get_subscription_status/?company_id=1";
    // const subscriptionCheckerUrl = '//dummyjson.com/test';

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const response = await fetch(subscriptionCheckerUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch subscription status');
        }
        const data = await response.json();

        if (data == 1) {
          onSubscriptionCheck(true);  // Notify that subscription is active
        } else {
          onSubscriptionCheck(false); // Notify that subscription has expired
        }
      } catch (error) {
        console.error('Error fetching subscription status:', error);
        onSubscriptionCheck(false); // Handle error by assuming subscription is expired
      }
    };

    checkSubscription();

    const intervalId = setInterval(checkSubscription, 3600000);

    return () => clearInterval(intervalId);
  }, [onSubscriptionCheck]);

  return null; // No need to render anything from this component
};

export default SubscriptionChecker;
