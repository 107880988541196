import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TrashBinIcon from "images/trash-bin.svg"
import { getFirstQuestionList, getLastQuestionList } from '../api/history/index';
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from "components/Modal/ConfirmDeleteModal";

// Images 
import HistoryThumbnail from "images/history/history-thumbnail.png";
import { notifyError, notifySuccess } from 'utils/customToasts';
import { useDispatch } from 'react-redux';
import { deleteHistoryByDateThunk } from '../slices/chat/thunk';

const History = () => {

  // States
  const [data, setData] = useState([]);
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  // Fetch data from API
  const fetchQuestionsList = async () => {
    try {
      const userId = 1;

      const payload = {
        userId
      }

      const qList = await getLastQuestionList(payload);

      setData(qList);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchQuestionsList();
  }, []);

  // Delete item
  const handleDelete = (e, id) => {
    axios.delete(`https://api.example.com/data/${id}`)
      .then(() => {
        setData(data.filter(item => item.id !== id));
      })
      .catch(error => {
        console.error('There was an error deleting the item!', error);
      });
  };

  // generate redirection uri
  const getChatLink = (id, date) => {
    return `/chat-history?query_id=${id}&date=${date}`;
  }

  // On Select Delete
  const selectDeleteHistory = (e, selectedData) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedHistory(selectedData);
  }

  // On Cancel Delete
  const cancelDeleteHistory = () => {
    setSelectedHistory(null);
  }

  // On Confirm Delete
  const onConfirmDelete = async () => {
    try {
      const payload = {
        userId: 1,
        date: selectedHistory?.question_date
      }

      const response = await dispatch(deleteHistoryByDateThunk(payload));

      if (response?.type === "deleteHistoryByDate/fulfilled") {
        setData(prev => prev.filter(history => history?.question_date !== payload.date));
        notifySuccess(response?.payload?.message || `History Deleted for ${payload?.date || "given date"}`);
      } else {
        notifyError(response?.payload?.message || `Failed to delete history for ${payload?.date || "given date"}`);
      }

      setSelectedHistory(null);
    } catch (err) {
      notifyError(err?.message || "Failed to delete history")
    }
  }

  if (loading) {
    return <div className="text-white text-center mt-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center mt-10">Error: {error.message}</div>;
  }

  if (data.length === 0) {
    return <div className="text-white text-center mt-10">No data available</div>;
  }

  return (
    <div className="p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {data.map(item => (
          <Link
            key={item.query_id}
            to={getChatLink(item.query_id, item.question_date)}
            className="bg-primary-3 p-4 rounded-lg relative flex flex-col items-center row-gap-4"
          >
            <img src={HistoryThumbnail} alt={item.question} className="w-full h-40 object-cover rounded-lg" />

            <div className="w-full flex items-start justify-between gap-1 mt-2">
              {/* Content */}
              <div>
                <h3 className="text-white text-lg line-clamp-1">{item.question}</h3>
                <p className="text-gray-400 mt-[6px]">Added on {item?.question_date}</p>
              </div>

              {/* Delete Button */}
              <button
                onClick={(e) => selectDeleteHistory(e, item)}
                className="shrink-0 h-[34px] w-[34px] border border-primary-light text-white p-1 rounded-[10px] "
              >
                <img src={TrashBinIcon} alt="Delete Icon" />
              </button>
            </div>
          </Link>
        ))}
      </div>

      <ConfirmDeleteModal
        open={!!selectedHistory}
        title="Delete History"
        content={`Are you sure you want to delete history for date ${selectedHistory?.question_date}`}
        onConfirm={onConfirmDelete}
        onCancel={cancelDeleteHistory}
      />
    </div>
  );
};

export default History;
