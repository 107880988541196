import { useAlertList, useAlertMethods } from "hooks/alertHooks";
import loadingGif from 'images/loader2.webp';
import AlertsTable from "./AlertsTable";
import UpdateAlertModal from "./UpdateAlertModal";
import { useState } from "react";
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal';
import { deleteAlertById } from 'api/alerts/index';
import { useAlertsContext } from 'components/Alerts/AlertsContext';

const AlertsList = () => {

    const { removeAlert } = useAlertMethods();
    const { alertsList, isFetching } = useAlertList();
    const { setShowModal } = useAlertsContext();

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = (params) => {
        setSelectedAlert(params);
        setShowDeleteModal(true);
    }

    const onConfirmDelete = async (params) => {
        try {
            await deleteAlertById(selectedAlert.idalerts);
            setShowDeleteModal(false);
            removeAlert(selectedAlert);
            setSelectedAlert(null);
        } catch (err) {
            console.error("Delete Alert Error: ", err);
        }
    }

    const handleUpdate = (params) => {
        setSelectedAlert(params);
        setShowUpdateModal(true);
    }

    return (
        <div className="mx-10 mt-20 mb-20 bg-primary-3 p-8 flex flex-col text-white text-left gap-6 rounded-lg">
            <div className="flex items-center gap-2 justify-between">
                <h1>Alert List</h1>
                {
                    !isFetching && alertsList?.length >= 0 && (
                        <button
                            onClick={() => setShowModal((prev) => !prev)}
                            className={`bg-primary-1 text-sm text-white py-2 px-2.5 rounded-lg`}
                        >
                            Create New Alert
                        </button>
                    )
                }
            </div>

            {
                isFetching && (
                    <div className={`flex flex-col items-center`}>
                        <img src={loadingGif} alt="loading" className={`h-6 w-6`} />
                        <span>Loading Alerts</span>
                    </div>
                )
            }

            {
                !isFetching && alertsList?.length <= 0 && (
                    <div className={`flex flex-col items-center`}>
                        <span>You don't have any alerts, Create a alert now.</span>
                        <button
                            onClick={() => setShowModal((prev) => !prev)}
                            className={`bg-primary-1 mt-4 text-sm text-white p-3 rounded-lg`}
                        >
                            Create New Alert
                        </button>
                    </div>
                )
            }

            {
                !isFetching && alertsList?.length >= 1 && (
                    <AlertsTable onEdit={handleUpdate} onDelete={handleDelete} data={alertsList} />
                )
            }

            <UpdateAlertModal data={selectedAlert} showModal={showUpdateModal} setShowModal={setShowUpdateModal} />

            <ConfirmDeleteModal
                open={showDeleteModal}
                title="Delete Alert"
                content={`Are you sure you want to delete this alert?`}
                onConfirm={onConfirmDelete}
                onCancel={() => setShowDeleteModal(false)}
            />

        </div>
    );
}

export default AlertsList