import styles from '../styles/dialogue.module.css';
import Layout from '../components/Layout';
import CreateDashboardModal from 'components/Dashboard/CreateDashboradModal';
import DashBoardGraphList from 'components/Dashboard/DashboardGraphList';
import CreateDashboardAlert from 'components/Dashboard/CreateDashboardAlert';
import DeleteGraphModal from 'components/Dashboard/DeleteGraphModal';
import GraphPopup from 'components/Dashboard/GraphPopup';
import { useDashboardList } from 'hooks/dashboardHooks';
const DashboardWindow = () => {

    useDashboardList();

    return (
        <Layout hasDashboardFilter>
            <div className={styles.newDialog}>
                <DashBoardGraphList />
                <CreateDashboardModal />
                <CreateDashboardAlert />
                <DeleteGraphModal />    
                <GraphPopup />
            </div>
        </Layout>
    );
}

export default DashboardWindow;