import { useEffect, useMemo, useRef } from "react"
import { getTimeDistance } from "utils/helper";

export const SingleComment = ({ comment }) => {

    const timeDistance = useMemo(() => {
        return getTimeDistance(comment?.timestamp);
    }, [comment?.timestamp]);

    return (
        <div className="animate-grow p-4 flex flex-col gap-3 rounded-lg bg-[#0D1B2A]">
            <div className="flex gap-2 items-center">
                <div className={`w-5 h-5 rounded-full bg-orange-500 flex items-center justify-center`}>
                    {comment?.user_name?.[0] || "U"}
                </div>
                <span className="grow">{comment?.user_name || "User"}</span>
                <span className="text-[#B7B7B7] font-normal text-sm">{timeDistance}</span>
            </div>

            <p>
                {comment?.comment}
            </p>
        </div>
    )
}

const CommentList = ({ comments }) => {

    const commentListRef = useRef();

    useEffect(() => {
        // Scroll to the bottom of the comment list
        if (commentListRef.current) {
            commentListRef.current.scrollTop = commentListRef.current.scrollHeight;
        }
    }, [comments]); // This will run every time comments change

    return (
        <div ref={commentListRef} className="max-h-[50svh] overflow-scroll flex flex-col gap-3 rounded-lg">
            {
                comments.map((comment, index) => (
                    <SingleComment key={`comment-${comment.user_id}-${index}`} comment={comment} />
                ))
            }
        </div>
    );
};

export default CommentList;