import React from 'react';
import Layout from 'components/Layout';
import Alerts from "components/Alerts";

const AlertsWindow = () => {
    return (
        <Layout>
            <div>
                <Alerts>
                    <Alerts.AlertsList />
                    <Alerts.CreateAlertModal />
                </Alerts>
            </div>
        </Layout>
    );
};

export default AlertsWindow;
