import DashBoardGraph from "components/Dashboard/DashboardGraph";
import { useSelector } from 'react-redux';
import { useDashboardCharts } from '../../hooks/dashboardHooks';
import loadingGif from 'images/loader2.webp';
import { useMemo } from "react";

const DashBoardGraphList = () => {
    const { dashboardCharts, isLoading } = useDashboardCharts();
    const { dashboards, selectedDashboard } = useSelector(state => state.DashboardReducer);

    // Dashboard keymap [id -> name] for display name
    const dashboardKeyMap = useMemo(() => {
        const keyData = {};
        dashboards.forEach(dashboard => {
            keyData[dashboard.id] = dashboard.name;
        });
        return keyData;
    }, [dashboards]);

    const dashboardFilter = useMemo(() => {
        if (selectedDashboard.value === null){
            return dashboardCharts;
        }

        return dashboardCharts.filter(chart => chart.dashboard_id === selectedDashboard.id);
    }, [selectedDashboard, dashboards, dashboardCharts]);

    if (isLoading) return (
        <div className={`flex flex-col items-center justify-center mt-10`}>
            <img src={loadingGif} alt="loading" className={`h-6 w-6`} />
            <span>Loading Dashboard</span>
        </div>
    )

    if (!dashboardFilter?.length) return (
        <div className={`flex flex-col items-center justify-center mt-10`}>
            <span>No charts available! Add your prefered charts from your chats</span>
        </div>
    )

    return (
        <div className="grid grid-cols-2 gap-6 p-10">
            {
                dashboardFilter.map((chart, index) => {
                    return (
                        <DashBoardGraph
                            key={`dashboard-chart-${chart.id || index}`}
                            chartData={chart}
                            selectedDashboard={dashboardKeyMap?.[chart?.dashboard_id] || "Dashboard Chart"}
                        />
                    )
                })
            }
        </div>
    )
}

export default DashBoardGraphList;