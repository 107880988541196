import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersListThunk } from 'slices/users/thunk';
import { setFetchUsers } from "slices/users/reducer";

export const useUsersList = () => {
    // Local State
    const [loading, setLoading] = useState(false);

    // Redux State
    const dispatch = useDispatch();
    const { users, fetchUsers } = useSelector(state => state.UsersReducer);

    // Fetch Dashboards
    const fetchUsersList = async () => {
        try {
            const payload = {
                organization_id: 1
            }
            await dispatch(getUsersListThunk(payload));
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            await dispatch(setFetchUsers(false));
            setLoading(false);
        }
    }

    useEffect(() => {
        if (fetchUsers) {
            setLoading(true);
            fetchUsersList();
        } else {
            setLoading(false);
        }
    }, [fetchUsers]);

    // Return data
    return {
        users,
        isLoading: loading,
        usersCount: users.length,
    }
}