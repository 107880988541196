import * as yup from 'yup';

const emailSchema = yup
    .string()
    .required("Email address is required")
    .email("Enter a valid email address");

const passwordSchema = yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(20, "Password must not exceed 20 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
    .matches(/^\S*$/, "Password must not contain spaces");

// Login schema using the shared validation rules
export const LoginUserSchema = yup.object().shape({
    email: emailSchema,
    password: passwordSchema,
});

// Register schema using the shared validation rules and additional fields
export const RegisterUserSchema = yup.object().shape({
    email: emailSchema,
    password: passwordSchema,
    full_name: yup.string().trim().required("Please enter a full name"),
    company_name: yup.string().trim().required("Please enter a company name"),
});