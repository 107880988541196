import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedGraph, setShareCollabrationVisibility } from 'slices/collabration/reducer';

// Images
// import ShareIcon from "images/share-icon.svg";
// import CollabrotiveIdeaIcon from "images/collabrate-trio-circle-icon-2.svg";
import ChatIcon from "images/chat-alt.svg";



const ShareToCollaborationButton = ({ data = null }) => {

    const dispatch = useDispatch();

    const openShareGraphModal = () => {
        dispatch(setSelectedGraph(data));
        dispatch(setShareCollabrationVisibility(true));
    }

    if (!data) {
        return null
    }

    return (
        <img
            onClick={openShareGraphModal}
            className={`h-5 w-5 cursor-pointer`}
            src={ChatIcon}
            alt="Add to Collaboration"
        />
    );
};

export default ShareToCollaborationButton;
