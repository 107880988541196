import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUsersList } from 'api/collaborativeForum/index';

// Get dashboards
export const getUsersListThunk = createAsyncThunk(
    "getUsersList",
    async (payload) => {
        try {
            const response = await getUsersList(payload);
            return response;
        } catch (error) {
            return error;
        }
    }
)