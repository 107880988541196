import AlertsList from "components/Alerts/AlertsList";
import CreateAlertModal from "components/Alerts/CreateAlertModal";
import AlertsProvider from "components/Alerts/AlertsProvider";
import ModalToggleButton from "components/Alerts/ModalToggleButton";

const Alerts = ({children}) => {
    return (
        <AlertsProvider>
            {children}
        </AlertsProvider>
    );
}

Alerts.AlertsList = AlertsList;
Alerts.CreateAlertModal = CreateAlertModal;
Alerts.ModalToggleButton = ModalToggleButton;

export default Alerts;