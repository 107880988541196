import styles from "styles/dialogue.module.css";
import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import CollabrativeForumListing from "components/Collabration/CollabrativeForumListing";

const CollaborativeForumListWindow = () => {

    useEffect(() => {
    }, []);

    return (
        <Layout>
            <div className={styles.newDialog}>
                <CollabrativeForumListing />
            </div>
        </Layout>
    );
};

export default CollaborativeForumListWindow;