import Axios from "api/Axios";

export const register = async (payload) => {
    const response = await Axios.post(`/auth/register`, payload);
    return response.data;
}


export const login = async (payload) => {
    const response = await Axios.post(`/auth/login`, payload);
    return response.data;
}

export const logout = async (payload) => {
    const response = await Axios.post(`/auth/logout`, payload);
    return response.data;
}

export const forgotPassword = async (payload) => {
    const response = await Axios.post(`/auth/forgot-password`, payload);
    return response.data;
}

export const resetPassword = async (payload) => {
    const response = await Axios.post(`/auth/reset-password`, payload);
    return response.data;
}