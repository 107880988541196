import ChatWindow from 'pages/chatWindow';
import ChatWindowAlt from 'pages/chatWindowAlt';
import HistoryWindow from 'pages/historyWindow';
import HistoryChatWindow from 'pages/historyChatWindow';
import AlertsWindow from 'pages/alertsWindow';
import DashboardWindow from 'pages/dashboardWindow';
import CollaborativeForumListWindow from 'pages/collaborativeForumListWindow';
import CollabrativeForumDetail from 'components/Collabration/CollabrativeForumDetail';
import CollaborativeForumDetailWindow from 'pages/collaborativeForumDetailWindow';

const protectedRoutes = [
    {
        path: "/chat-old",
        name: "Chat Page",
        element: <ChatWindow />,
    },
    {
        path: "/chat",
        name: "Chat Page",
        element: <ChatWindowAlt />,
    },
    {
        path: "/chat-history",
        name: "Chat History",
        element: <HistoryChatWindow />,
    },
    {
        path: "/history",
        name: "History",
        element: <HistoryWindow />,
    },
    {
        path: "/alerts",
        name: "Alerts",
        element: <AlertsWindow />,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        element: <DashboardWindow />
    },
    {
        path: "/collaborative-forum",
        name: "Collaborative Forum Listing",
        element: <CollaborativeForumListWindow />
    },
    {
        path: "/collaborative-forum-detail",
        name: "Collaborative Forum Detail",
        element: <CollaborativeForumDetailWindow />
    }
]

export default protectedRoutes;