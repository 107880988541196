const reasons = [
    "Issue with Summary",
    "Issue with Graph",
    "Issue with Content",
    "Technical Issues",
    "Other Issues"
]

const ReasonButton = (props) => {
    const { children, ...restProps } = props
    return (
        <button className="py-5 px-6 border rounded-lg border-primary-light" {...restProps}>
            {props.children}
        </button>
    )
}

const ReasonSelection = ({ onSelect }) => {
    return (
        <div className="mt-8 flex gap-5 flex-wrap">
            {
                reasons.map((reason, index) => (
                    <ReasonButton key={index} onClick={() => {
                        onSelect(reason);
                    }}>
                        {reason}
                    </ReasonButton>
                ))
            }
        </div>
    )
}

export default ReasonSelection;