import HighchartsComponentAlt from 'components/HighchartsComponentAlt';
import styles from 'styles/dashboardGraphPopup.module.css';
import DOMPurify from 'dompurify';
// Images
import ChatIcon from 'images/chat-alt.svg';
import SubmitIcon from 'images/submit-icon.svg';
import { getJSONObject } from 'utils/helper';

const RightSideContainer = ({ data }) => {

    const graphData = getJSONObject(data?.selected_data_from_history?.df_data || null);
    const plotData = getJSONObject(data?.selected_data_from_history?.highchart_code || null);
    const actionsData = getJSONObject(data?.selected_data_from_history?.actions || null);
    const summaryData = getJSONObject(data?.selected_data_from_history?.summary || null);

    return (
        <div className="col-span-9 flex flex-col gap-4">
            {
                plotData && graphData && (
                    <div className="border border-secondary-1 rounded-xl">
                        <HighchartsComponentAlt
                            data={graphData}
                            dataToPlot={plotData}
                        />
                    </div>
                )
            }
            
            {actionsData && (
                <div>
                    <h3 className="font-medium text-base">Recommended actions:</h3>
                    <div className={`mt-2 overflow-scroll text-xs p-5 ${styles.recommended_actions}`}>
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(actionsData) }} />
                    </div>
                </div>
            )}

            {summaryData && (
                <div
                    className={`mt-2 ${styles.graph_summary}`}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(summaryData) }}
                />
            )}
        </div>
    )
}

export default RightSideContainer;