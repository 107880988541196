import DataTable from "react-data-table-component";
// Images
import TrashBinIcon from "images/trash-bin.svg";
import PenIcon from "images/pen-icon.svg";

const BG_COLOR = "#0B1723";
const TEXT_COLOR = "#FFFFFF";
const BORDER_COLOR = "#000000";

const AlertsTable = ({ data = [], onEdit, onDelete }) => {


    const customTheme = {
        table: {
            style: {
                border: `1px solid ${BORDER_COLOR}`,
                borderRadius: "8px",
                overflow: "hidden",
                backgroundColor: BORDER_COLOR,
            }
        },

        tableWrapper: {
            style: {
                display: 'table',
            },
        },

        headRow: {
            style: {
                color: TEXT_COLOR,
                backgroundColor: BG_COLOR,
                borderBottom: "none",
                borderRadius: "8px 8px 0 0 ",
            },
        },

        rows: {
            style: {
                color: TEXT_COLOR,
                backgroundColor: BG_COLOR,
                borderBottom: "none !important",
            },
        },

        headCells: {
            style: {
                border: `1px solid ${BORDER_COLOR}`,
            }
        },

        cells: {
            style: {
                border: `1px solid ${BORDER_COLOR}`,
            }
        }
    }

    const conditionTypes = {
        ">": "Above",
        "<": "Below",
        "=": "Equal",
    }

    const columns = [
        {
            name: 'Alert Name',
            selector: row => row?.question || "-NA-",
        },
        {
            name: 'Alert Field',
            selector: row => row?.value_column || "-NA-",
        },
        {
            name: 'Condition',
            selector: row => conditionTypes?.[row?.condition_type] || "-NA-",
        },
        {
            name: 'Alert Threshold',
            selector: row => row?.threshold || "-NA-",
        },
        {
            name: 'Created on',
            selector: row => {
                const creationDate = row?.created_at ? new Date(row.created_at).toLocaleDateString() : "-NA-"
                return creationDate
            },
        },
        {
            name: 'Action',
            selector: row => (
                <div className="flex gap-3 items-center justify-center">
                    {
                        row?.idalerts ? (
                            <>
                                <img className="cursor-pointer" title={"Edit Alert"} onClick={() => onEdit(row)} src={PenIcon} />
                                <img className="cursor-pointer" title={"Delete Alert"} onClick={() => onDelete(row)} src={TrashBinIcon} />
                            </>
                        ) : <span className="text-gray-500">Added Now</span>
                    }
                </div>
            ),
        }
    ];

    return (
        <div className="w-full">
            <DataTable
                data={data}
                columns={columns}
                customStyles={customTheme}
            />
        </div>
    )
}

export default AlertsTable;