import CheckIcon from "images/check-square-icon.svg";
import UncheckIcon from "images/uncheck-square-icon.svg";

const reasons = [
    "Issue with Summary",
    "Issue with Graph",
    "Issue with Content",
    "Technical Issues",
    "Other Issues"
];

const ReasonOptions = {
    "Issue with Summary": {
        minRequired: 1,
        options: [
            {
                title: "Was the summary unclear or difficult to understand?",
                value: "Was the summary unclear or difficult to understand?"
            },
            {
                title: "Did the summary miss any key points?",
                value: "Did the summary miss any key points?",
            },
            {
                title: "Was the summary inaccurate or misleading?",
                value: "Was the summary inaccurate or misleading?"
            }
        ]
    },
    "Issue with Graph": {
        minRequired: 0,
        options: [
            {
                title: "Was the graph difficult to read or interpret?",
                value: "Was the graph difficult to read or interpret?"
            },
            {
                title: "Did the graph have incorrect or misleading data?",
                value: "Did the graph have incorrect or misleading data?"
            },
            {
                title: "Was the graph missing any important information",
                value: "Was the graph missing any important information?"
            }
        ]
    },
    "Issue with Content": {
        minRequired: 0,
        options: [
            {
                title: "Was the content irrelevant to your query?",
                value: "Was the content irrelevant to your query?"
            },
            {
                title: "Was the content incorrect or misleading?",
                value: "Was the content incorrect or misleading?"
            },
            {
                title: "Was the content too detailed or not detailed enough?",
                value: "Was the content too detailed or not detailed enough?"
            }
        ]
    },
    "Technical Issues": {
        minRequired: 0,
        options: [
            {
                title: "Did you encounter any errors or bugs?",
                value: "Did you encounter any errors or bugs?"
            },
            {
                title: "Was the data visualization not loading properly?",
                value: "Was the data visualization not loading properly?"
            }
        ]
    },
    "Other Issues": {
        minRequired: 0,
        options: []
    }
}

const ReasonCheckbox = (props) => {
    const { children, ...restProps } = props
    return (
        <div className="py-5 px-6 flex items-center gap-4 border rounded-lg border-primary-light">
            <div className="relative">
            <input
                type="checkbox"
                {...restProps}
                className={`absolute cursor-pointer opacity-0 w-full h-full`}
            />
            <img
                src={props.checked ? CheckIcon : UncheckIcon}
                alt={props.checked ? "Deselect" : "Select"}
                className="cursor-pointer"
             />
            </div>
            <span>{children}</span>
        </div>
    )
}

const ReasonCommentBox = ({ onChange }) => {
    return (
        <textarea
            rows={5}
            className={`resize-none bg-transparent rounded-lg border-primary-light focus:border-primary-1`}
            placeholder="Others comment here"
            onChange={onChange}
        />
    )
}

const ReasonForm = ({ reason, errors, selectedOptions, onOptionSelect, onCommentChange }) => {

    const handleCheckboxSelect = (event) => {
        const isChecked = event.target.checked;
        const value = event.target.value;
        onOptionSelect(isChecked, value);
    }

    return (
        <div className="mt-9 flex flex-col gap-4">
            {
                errors?.options && (
                    <span className="text-red-600"> {errors?.options}</span>
                ) 
            }
            {
                ReasonOptions[reason].options.map((option, index) => (
                    <ReasonCheckbox
                        onChange={handleCheckboxSelect}
                        key={index}
                        checked={selectedOptions.includes(option.value)}
                        value={option.value}
                    >
                        {option.title}
                    </ReasonCheckbox>
                ))
            }
            <ReasonCommentBox onChange={onCommentChange} />
            {
                errors?.comment && (
                    <span className="text-red-600 "> {errors?.comment}</span>
                ) 
            }
        </div>
    )
}

export default ReasonForm;