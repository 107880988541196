import { useEffect, useState } from "react"
import { getAllAlerts } from 'api/alerts/index';
import { useDispatch, useSelector } from "react-redux";
import { addAlert, deleteAlert, setAlerts, updateAlert } from "slices/alerts/reducer";

export const useAlertMethods = () => {
    const dispatch = useDispatch();

    const alerts = useSelector(
        (state) => state.AlertReducer.alerts
    );

    const setNewAlerts = (data) => {
        dispatch(setAlerts(data));
    }

    const addNewAlert = (data) => {
        dispatch(addAlert(data));
    }

    const removeAlert = (data) => {
        dispatch(deleteAlert(data));
    }

    const updateAlertData = (data) => {
        dispatch(updateAlert(data));
    }

    return {
        alertsList: alerts,
        setAlertList: setNewAlerts,
        addAlert: addNewAlert,
        removeAlert: removeAlert,
        updateAlert: updateAlertData,
    };
}

export const useAlertList = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const alerts = useSelector(
        (state) => state.AlertReducer.alerts
    );

    const fetchAlertList = async () => {
        if(alerts?.length >= 1){
            return;
        }
        // Make API call to fetch alert list
        setLoading(true);
        try {
            const data = await getAllAlerts();
            setLoading(false);
            dispatch(setAlerts(data));
        } catch (e) {
            console.log("Error: ", e);
            dispatch(setAlerts([]));
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchAlertList();
    }, [])

    return {
        alertsList: alerts,
        isFetching: loading,
    };
}