// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { register, login } from 'api/auth/index';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('authToken'))
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async (token) => {
      const isValid = checkTokenWithBackend(token);
      if (!isValid) {
        localStorage.removeItem('authToken');
        setUser(null);
        navigate('/');
      }
    };

    const token = localStorage.getItem('authToken');
    if (token) {
      validateToken(token);
    }
  }, []);

  const checkTokenWithBackend = (token) => {
    if (token === "examaple") {
      return true;
    } else {
      return false;
    }
  }

  const loginUser = async (data) => {
    const { email, password } = data;
    // const token = await login({ email, password });
    const token = "examaple";
    if (token) {
      localStorage.setItem('authToken', token);
      setToken(token)
      setUser({ email, token });
      navigate('/chat'); // Navigate to chat on login
    }
  };

  const registerUser = async (payload) => {
    const response = await register(payload);

    console.log("Response =>", response);

    const responseData = {
      "refreshToken": "2eKvyfA4CZvnszwywW6-_iLNe5uejfpsEdoFnpO5Cu1MUCI9ADtPhg",
      "registration": {
        "applicationId": "9593779d-53c7-4f52-a1bd-ebbbbe5f142e",
        "data": {},
        "id": "04e10948-35e2-42ec-b18c-3bbd6ab7ab06",
        "insertInstant": 1727158745719,
        "lastLoginInstant": 1727158745719,
        "lastUpdateInstant": 1727158745719,
        "preferredLanguages": [],
        "roles": [],
        "tokens": {},
        "usernameStatus": "ACTIVE",
        "verified": true,
        "verifiedInstant": 1727158745719
      },
      "token": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjFyd3VWeE10Um9OM2gtREFtV3Via0Q3TGIyZyJ9.eyJhdWQiOiI5NTkzNzc5ZC01M2M3LTRmNTItYTFiZC1lYmJiYmU1ZjE0MmUiLCJleHAiOjE3MjcxNjIzNDUsImlhdCI6MTcyNzE1ODc0NSwiaXNzIjoiaHR0cHM6Ly9zYW5kYm94LmZ1c2lvbmF1dGguaW8iLCJzdWIiOiJmZDFlZWYxMy00Njg5LTQ2NTgtOTNlMy1mNzM2MTIyMzY3MTIiLCJqdGkiOiI5NDM3MWYwNS1hODhlLTQ0ZDctYmY1YS1mNjM5MGE4NjVhOGYiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJSRUdJU1RSQVRJT04iLCJhcHBsaWNhdGlvbklkIjoiOTU5Mzc3OWQtNTNjNy00ZjUyLWExYmQtZWJiYmJlNWYxNDJlIiwicm9sZXMiOltdLCJzaWQiOiI3MzBlZDc0YS0xZjZmLTQ2NzgtOGI2OC1jYzM0ZTBjMDhiMzAiLCJhdXRoX3RpbWUiOjE3MjcxNTg3NDUsInRpZCI6IjNlYjRkZTRiLWIzYmItNDdiMi1hMzc5LWJkMmQ4Mzc3OGQ3YiJ9.fZjicDaZ0AJwkQ8v8E76WrmcX7qgkTx99HwPclWQZIln7axJJx4ZCfhiX9TrMufuq6fCmR9D3u5fhNFEGpi9BfMqE6be1FHwFIWN7O8FhJnkHnJR6WbLIi1BfTgF3aiAiZkkTyaw6RG0Txcwkt-Uv21Q5f0aWciE-hmZCZFJv0iIlNGvK5ugFifVHshYZVN8ydStR1lpYPuswHrZKiyxC83v1rEa5Mjf6tKbE31YBMN6A0LUXWOpQa49pGYO3Ji1-EECCQEdBFufcRwXh8q7_ZxlD4U-9nCN8uvsmZlwCsfNICskvIW_qWdsAifJDSMO41BkQ1Uve1lqDz4k6ttgiw",
      "tokenExpirationInstant": 1727162345721,
      "user": {
        "active": true,
        "connectorId": "e3306678-a53a-4964-9040-1c96f36dda72",
        "data": {},
        "email": "bhaskeranand+test@codewave.com",
        "fullName": "Bhasker Anand Jeenwal",
        "id": "fd1eef13-4689-4658-93e3-f73612236712",
        "insertInstant": 1727158745703,
        "lastLoginInstant": 1727158745719,
        "lastUpdateInstant": 1727158745703,
        "memberships": [],
        "passwordChangeRequired": false,
        "passwordLastUpdateInstant": 1727158745712,
        "preferredLanguages": [],
        "registrations": [],
        "tenantId": "3eb4de4b-b3bb-47b2-a379-bd2d83778d7b",
        "twoFactor": {
          "methods": [],
          "recoveryCodes": []
        },
        "usernameStatus": "ACTIVE",
        "verified": true,
        "verifiedInstant": 1727158745703
      }
    }
    // localStorage.setItem('authToken', token);
    // setToken(token)
    setUser({ email: payload.email, token: response });
    navigate('/chat');
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setUser(null);
    navigate('/');
  };

  return (
    <AuthContext.Provider value={{ token, user, login: loginUser, registerUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};