const Modal = (props) => {
    const modalVisibility = props.open ? "block" : "hidden"
    return (
        <div className={`fixed p-5 overflow-scroll w-full h-full backdrop-blur top-0 left-0 flex items-center ${modalVisibility}`}>
            <dialog {...props}>
                {props.children}
            </dialog>
        </div>
    );
}

export default Modal