import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getLastQuestionList } from 'api/history/index';
import { Link, useSearchParams } from 'react-router-dom';
import LeftSideContainer from "components/Collabration/LeftSideContainer";
import RightSideContainer from "components/Collabration/RightSideContainer";

// Images 
import ArrowLeftIcon from "images/arrow-left-icon.svg";
import { getForumDetails } from '../../api/collaborativeForum/index';

const CollabrativeForumDetail = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [searchParams] = useSearchParams();
    const forumId = searchParams.get('forum_id');

    const fetchForumData = async () => {
        setLoading(true);
        try {
            const payload = { forumId };
            const response = await getForumDetails(payload);
            setData(response.data);
        } catch (err) {
            console.log("Error:", err);
        } finally {
            setLoading(false);
        }
    }

    const onAddComment = (comment) => {
        const newCommentPayload = {
            forum_id: forumId,
            comment: comment,
            timestamp: new Date(),
            user_name: "You"
        }

        setData(prev => {
            return {
                ...prev,
                comments: [
                   ...prev.comments,
                    newCommentPayload
                ]
            }
        })
    }

    useEffect(() => {
        fetchForumData();
        return () => {
            setData({});
        }
    }, [forumId])


    if (loading) {
        return <div className="text-white text-center mt-10">Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500 text-center mt-10">Error: {error?.message}</div>;
    }

    if (!data) {
        return <div className="text-white text-center mt-10">No data available</div>;
    }

    return (
        <div className="p-10">

            <h1 className="flex gap-4 items-center py-4 px-3 border-b border-[#BEEF9E66] font-medium text-2xl">
                <Link
                    to={`/collaborative-forum`}
                    className="cursor-pointer"
                >
                    <img src={ArrowLeftIcon} alt="Back" />
                </Link>
                Collaborative forum
            </h1>

            <div className="grid grid-cols-12 gap-6 mt-8">
                <LeftSideContainer data={data} forumId={forumId} onAddComment={onAddComment} />
                <RightSideContainer data={data} forumId={forumId} />
            </div>
        </div>
    );
};

export default CollabrativeForumDetail;
