import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import "App.css";
import "styles/global.css";
import SubscriptionChecker from './components/SubscriptionChecker';
import AppRoutes from 'routes';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import publicRoutes from 'routes/publicRoutes';
import { useLocation } from 'react-router';

function App() {

  const [subscriptionActive, setSubscriptionActive] = useState(true);

  const handleSubscriptionStatus = (isActive) => {
    setSubscriptionActive(isActive);
  };

  const location = useLocation();
  const publicPaths = publicRoutes.map(route => route.path);

  if (!subscriptionActive && !publicPaths.includes(location.pathname)) {
    // Render only the blank page with the warning message
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'black' }}>
        <h1 style={{ color: 'white' }}>Your subscription has expired. Please renew to continue.</h1>
      </div>
    );
  }

  return (
    <div className="App">
      <SubscriptionChecker onSubscriptionCheck={handleSubscriptionStatus} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Storymetrics</title>
        <link rel="canonical" href="www.codewave.com" />
      </Helmet>

      <AppRoutes />
      <ToastContainer
        className={"p-0"}
        limit={3}
        stacked={true}
        newestOnTop
      />
    </div>
  );
}

export default App;