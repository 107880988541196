import Axios from "api/Axios";

export const getChatHistoryList = async (payload) => {
    const { userId = 1 } = payload;
    const response = await Axios.get(`/history/?user_id=${userId}`);
    return response.data;
}

export const getChatHistoryListByDate = async (payload) => {
    const { userId = "", date = "" } = payload;
    const response = await Axios.get(`/fetch_logs_by_date/?user_id=${userId}&date=${date}`);
    return response.data;
}

export const getFirstQuestionList = async (payload) => {
    const { userId = 1 } = payload;
    const response = await Axios.get(`/get_first_question/?user_id=${userId}`);
    return response.data;
}

export const getLastQuestionList = async (payload) => {
    const { userId = 1 } = payload;
    const response = await Axios.get(`/get_last_question/?user_id=${userId}`);
    return response.data;
}

export const getUniqueQuestionList = async (payload) => {
    const { userId = "" } = payload;
    const response = await Axios.get(`/unique%20history%20for%20alert/?user_id=${userId}`);
    return response.data;
}

export const deleteHistoryByDate = async (payload) => {
    const { userId = "", date = "" } = payload;
    const response = await Axios.delete(`/delete_history_date_${date}`, {
        params: {
            user_id: userId,
        }
    });
    return response.data;
}