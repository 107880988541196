import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alerts: [],
    errors: {}
}

const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        setAlerts: (state, action) => {
            const payload = action.payload;
            state.alerts = payload
        },

        addAlert: (state, action) => {
            const payload = action.payload;
            state.alerts = [...state.alerts, payload]
        },

        updateAlert: (state, action) => {
            const payload = action.payload;
            state.alerts = state.alerts.map(alert => alert.idalerts === payload.idalerts ? payload : alert)
        },

        deleteAlert: (state, action) => {
            const payload = action.payload;
            state.alerts = state.alerts.filter(alert => alert.idalerts !== payload.idalerts)
        },
    },
});

export const { setAlerts, addAlert, updateAlert, deleteAlert } = alertSlice.actions;

export default alertSlice.reducer;