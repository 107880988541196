import Modal from "components/Modal";
import { useAlertsContext } from "components/Alerts/AlertsContext";
import Dropdown from "components/Dropdown";
import { useEffect, useState } from 'react';
import { CreateAlertSchema } from '../../validations/alerts';
import ConfirmDeleteModal from "components/Modal/ConfirmDeleteModal";
import { useUniqueQuestionList } from 'hooks/questionHooks';
import { deleteAlertById, updateAlert } from 'api/alerts/index';
import loadingGif from 'images/loader2.webp';
import { useAlertMethods } from 'hooks/alertHooks';
import SearchDropdown from "components/Dropdown/SearchDropdown";

const UpdateAlertModal = ({ data, showModal, setShowModal }) => {

    const { removeAlert, updateAlert: updateAlertData } = useAlertMethods();

    const [selectedQuery, setSelectedQuery] = useState(null);
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [selectedCondition, setSelectedCondition] = useState(null);
    const [selectedEmail, setSelectedEmail] = useState("");
    const [selectedThreshold, setSelectedThreshold] = useState("");

    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({ errorCount: 0 });
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const questions = useUniqueQuestionList(1);

    const conditionOptions = [
        {
            label: "Above",
            value: ">",
        },
        {
            label: "Below",
            value: "<",
        },
        {
            label: "Equal",
            value: "=",
        },
    ]

    const onQueryChange = (data) => {
        setSelectedQuery(data);
        setSelectedColumn(null);
    }

    const verifyPayload = async (payload) => {
        try {
            return CreateAlertSchema.validate(payload, { abortEarly: false });
        } catch (err) {
            console.log("Alert Validation Error: ", err);
            throw new Error(err)
        }
    }

    const handleUpdateAlert = async () => {
        setLoading(true);
        try {
            const payload = {
                alert_id: data.idalerts,
                query_id: parseInt(selectedQuery?.value) || null,
                value_column: selectedColumn?.value,
                condition: selectedCondition?.value,
                threshold: selectedThreshold,
                email: selectedEmail,
            }

            const verifiedPayload = await verifyPayload(payload);
            const result = await updateAlert(verifiedPayload);
            setLoading(false);
            updateAlertData({
                ...data,
                query_id: parseInt(selectedQuery?.value) || null,
                question: selectedQuery?.label,
                value_column: selectedColumn?.value,
                condition_type: selectedCondition?.value,
                threshold: selectedThreshold,
                email: selectedEmail,
            });
            setFormErrors({ errorCount: 0 });
            setShowModal(false);

        } catch (err) {
            let errorCount = 0
            const errObj = {};
            err.inner.forEach(error => {
                errObj[error.path] = error.message;
                errorCount += 1
            })
            setFormErrors({ ...errObj, errorCount });

            console.log("Error", errObj)
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteAlert = async () => {
        try {
            await deleteAlertById(data.idalerts);
            setShowDeleteModal(false);
            removeAlert(data);
            resetModalStates();
        } catch (err) {
            console.error("Delete Alert Error: ", err);
        }
    }

    const resetModalStates = () => {
        setLoading(false);
        setFormErrors({ errorCount: 0 })
        setShowModal(false);
    }

    useEffect(() => {
        // Set update state
        if (showModal && data?.idalerts) {
            // Preselect The Query
            const queryData = {
                label: data?.question || "Select Query",
                value: data?.query_id || null,
                columns: questions.find(x => x.value === data?.query_id)?.columns || []
            }
            setSelectedQuery(queryData);

            // Preselect The Column
            const columnData = {
                label: data?.value_column || "Select Column",
                value: data?.value_column || null,
            }
            setSelectedColumn(columnData);

            // Preselect The Condition
            const conditionData = conditionOptions.find((cond) => {
                return cond.value === data?.condition_type;
            })
            setSelectedCondition(conditionData);

            // Preselect the email & threshold
            setSelectedEmail(data?.email || "");
            setSelectedThreshold(data?.threshold || "");
        }
    }, [showModal, data?.idalerts]);

    useEffect(() => {
        // flush the states
        return () => {
            resetModalStates();
        }
    }, [])

    return (
        <Modal
            position="top-right"
            className="w-[40rem] px-8 py-10 bg-primary-3 text-white rounded-lg text-left"
            open={showModal && data?.idalerts}
        >
            <h1>Create Alert</h1>
            <hr className="mt-2" />

            {
                formErrors.errorCount > 0 && (
                    <div className="mt-6 py-3 px-2 rounded bg-error-1 flex items-center justify-center">
                        <span className="text-red-600">Please correct the errors</span>
                    </div>
                )
            }

            {
                selectedQuery?.label ? (
                    <p className="mt-6">Alert me when <b>"{selectedQuery?.label}"</b> graph raises to above, equal to or below specific value.</p>
                ) : (
                    <p className="mt-6">Please select a query for which you want to create an alert.</p>
                )
            }


            {/* Select Query */}
            <div className="w-full mt-6">
                <SearchDropdown onChange={onQueryChange} selectedOption={selectedQuery} options={questions} />
                <span className="text-red-500 mt-2">{formErrors?.query_id}</span>
            </div>

            {/* Select columns for a selected query*/}
            <div className="mt-6 flex gap-6">
                <div className="w-full">
                    <Dropdown
                        hasError={formErrors?.value_column}
                        selectCTA="Select Column"
                        selectedOption={selectedColumn}
                        onChange={setSelectedColumn}
                        options={selectedQuery?.columns || []}
                    />
                    <span className="text-red-500 mt-2">{formErrors?.value_column}</span>
                </div>
            </div>

            {/* Alert Trigger */}
            <div className="mt-4 flex gap-6">

                <div className="w-full">
                    <Dropdown
                        hasError={formErrors?.condition}
                        selectedOption={selectedCondition}
                        onChange={setSelectedCondition}
                        options={conditionOptions}
                    />
                    <span className="text-red-500 mt-2">{formErrors?.condition}</span>
                </div>

                <div className="w-full">
                    <div className="relative w-full">
                        <input
                            type="text"
                            value={selectedThreshold}
                            onChange={(e) => setSelectedThreshold(e.target.value)}
                            className={`w-full p-3 rounded bg-[#0D1B2A] border ${formErrors?.threshold ? "border-red-500" : "border-[#382F5C]"}`}
                            placeholder="ThreshHold"
                        />
                    </div>
                    <span className="text-red-500 mt-2">{formErrors?.threshold}</span>
                </div>
            </div>

            <div className="mt-6">
                <label
                    htmlFor="email"
                    className={`block text-sm `}
                >
                    Email address
                </label>
                <input
                    type="email"
                    id="create-alert-email"
                    value={selectedEmail}
                    onChange={(e) => setSelectedEmail(e.target.value)}
                    className={`w-full mt-2 p-3 rounded bg-[#0D1B2A] border ${formErrors?.email ? "border-red-500" : "border-[#382F5C]"}`}
                    placeholder="Enter email id where you want to receive alerts."
                />
                <span className="text-red-500 mt-2">{formErrors?.email}</span>
            </div>

            <div className="flex justify-end mt-6 gap-4">
                <button disabled={loading} className="py-2 text-red-600" onClick={() => setShowDeleteModal(true)}>Delete Alert</button>
                <button disabled={loading} className="py-2 text-gray-300 px-4 border border-gray-200 rounded text-black" onClick={resetModalStates}>Cancel</button>
                <button
                    disabled={loading}
                    className="py-2 bg-primary-1 text-gray-400 px-4 rounded text-white flex gap-2 items-center disabled:bg-gray-600 disabled:text-gray-300"
                    onClick={handleUpdateAlert}
                >
                    {
                        loading && (
                            <img src={loadingGif} alt="loading" className={`h-4 w-4`} />
                        )
                    }
                    <span>
                        {loading ? "Saving" : "Save"}
                    </span>
                </button>
            </div>

            <ConfirmDeleteModal
                open={showDeleteModal}
                title="Delete Alert"
                content={`Are you sure you want to delete this alert?`}
                onConfirm={handleDeleteAlert}
                onCancel={() => setShowDeleteModal(false)}
            />
        </Modal>
    );
}

export default UpdateAlertModal