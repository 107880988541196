import styles from "../styles/dialogue.module.css";
import React, { useState, useEffect } from 'react';
import Chat from "../components/Chat"
import Header from '../components/Layout/Header';
import Layout from '../components/Layout';

const ChatWindow = () => {

  useEffect(() => {
  }, []);

  return (
    <Layout>
      <div className={styles.newDialog}>
        <Chat />
      </div>
    </Layout>
  );
};

export default ChatWindow;
