import { avatarBGColors } from 'constants';
export const generateDropdownOptions = (message, index) => {
	const restrictedCoulmn = [undefined, "month", "year", "date"]
	const forcastTypes = ["ARIMA", "DES", "MCMC", "Monte Carlo", "Scenario Analysis"]
	const columns = Object.keys(message?.graph?.[0] || {});
	const options = [];

	for (const column of columns) {

		if (restrictedCoulmn.includes(column?.trim()?.toLowerCase())) {
			continue;
		}

		for (const forecastType of forcastTypes) {
			options.push({
				label: `${forecastType} forecast for ${column}`,
				value: `${forecastType}-${column}`,
				column,
				forecastType: forecastType
			})
		}
	}

	return options;
}

/**
 * Excepts the json string and returns the javascript object Creted to handle errors & nested strings
 * @param {string} data JSON string
 * @returns {object}  Javascript Object | data(inputed)
 */
export const getJSONObject = (data) => {
	try {
		let parsedData = data;

		// Keep parsing while the result is a string
		while (typeof parsedData === "string") {
			parsedData = JSON.parse(parsedData);
		}

		return parsedData;
	} catch (err) {
		console.error("Error parsing JSON object:", err);
		return data;
	}
};

export const getTimeDistance = (date) => {

	const inputDate = new Date(date);

	// Ensure that the input is a valid Date object
	if (!(inputDate instanceof Date) || isNaN(inputDate.getTime())) {
		return "";
	}

	const now = new Date();
	const diffInMs = now - inputDate; // Difference in milliseconds
	const diffInSec = Math.floor(diffInMs / 1000); // Convert to seconds
	const diffInMin = Math.floor(diffInSec / 60); // Convert to minutes
	const diffInHour = Math.floor(diffInMin / 60); // Convert to hours
	const diffInDay = Math.floor(diffInHour / 24); // Convert to days
	const diffInMonth = Math.floor(diffInDay / 30); // Convert to months (approx)
	const diffInYear = Math.floor(diffInMonth / 12); // Convert to years (approx)

	// Return formatted string based on difference
	if (diffInSec < 60) {
		return `${diffInSec} second${diffInSec > 1 ? 's' : ''} ago`;
	} else if (diffInMin < 60) {
		return `${diffInMin} minute${diffInMin > 1 ? 's' : ''} ago`;
	} else if (diffInHour < 24) {
		return `${diffInHour} hour${diffInHour > 1 ? 's' : ''} ago`;
	} else if (diffInDay < 30) {
		return `${diffInDay} day${diffInDay > 1 ? 's' : ''} ago`;
	} else if (diffInMonth < 12) {
		return `${diffInMonth} month${diffInMonth > 1 ? 's' : ''} ago`;
	} else {
		return `${diffInYear} year${diffInYear > 1 ? 's' : ''} ago`;
	}
}

export const getAvatarBGColor = (inputSeed) => {
	if (typeof inputSeed === 'number') {
		return avatarBGColors[inputSeed % avatarBGColors.length];
	}

	if (typeof inputSeed === 'string') {
		let hash = 0;
		for (let i = 0; i < inputSeed.length; i++) {
			hash = (hash << 5) - hash + inputSeed.charCodeAt(i);
		}
		return avatarBGColors[Math.abs(hash) % avatarBGColors.length];
	}

	return "bg-avatar"; // Default to gray
}