import styles from "../styles/dialogue.module.css";
import React, { useState, useEffect } from 'react';
import Login from "../components/auth/login"

const LoginPage = () => { 
  useEffect(() => {
  }, []);

  return (
    <div className={styles.newDialog}>
      <Login />     
    </div>
  );
};

export default LoginPage;
