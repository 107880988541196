import React from 'react';
import HeaderAlt from "components/Layout/HeaderAlt";

const Layout = ({ children, hasDashboardFilter }) => {
  return (
    <div className="min-h-screen flex flex-col">

      <HeaderAlt
        hasDashboardFilter={hasDashboardFilter}
      />

      <main className="flex-grow">
        {children}
      </main>

    </div>
  );
};

export default Layout;
