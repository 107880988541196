import Axios from "api/Axios";

export const getForecast = async (payload) => {

    const urlencoded = new URLSearchParams();
    urlencoded.append('question', payload.question);
    urlencoded.append('dataframe', payload.dataframe);

    const response = await Axios.post(`/get_forecast/`, urlencoded, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response.data;
}   

export const downvoteMessage = async (payload) => {
    const response = await Axios.post(`/log_downvote/`, payload);
    return {
        ...response.data,
        success: response.status === 200,
    };
}