import Axios from "api/Axios";

// Add new dashboard
export const createDashboard = async (payload) => {
    const response = await Axios.post(`/create_dashboard/`, payload);
    return response.data;
}

// Get dashboard list
export const getDashboards = async () => {
    const response = await Axios.get(`/list_dashboards/`);
    return response.data;
}

// Add chart to dashboard
export const addChartToDashboard = async (payload) => {
    const response = await Axios.post(`/add_chart_to_dashboard/`, payload);
    return response.data;
}

// Get dashboard chart list
export const getDashboardCharts = async (payload) => {
    const response = await Axios.get(`/list_charts/`, { params: payload });
    return response.data;
}

// Delete chart from dashboard
export const deleteChartFromDashboard = async (payload) => {
    const response = await Axios.delete(`/delete_chart/`, { params: payload });
    return response.data;
}

// Move chart from one dashboard to another
export const moveChartToDashboard = async (payload) => {
    const { chartid, old_dashid, new_dashid } = payload
    const response = await Axios.post(`/move_chart/`, null, {
        params: {
            chartid,
            old_dashid,
            new_dashid
        }
    });
    return response.data;
}

