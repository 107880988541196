import Axios from "api/Axios";

// Get Platform Users List by associated Organization
export const getUsersList = async (payload) => {
    const { organization_id } = payload;
    const response = await Axios.get(`/list_users`, {
        params: {
            organization_id: organization_id || undefined
        }
    });
    return {
        data: response.data,
        success: response.status === 200,
    };
}

// Add new collabrative forum 
export const addForumEntry = async (payload) => {
    const response = await Axios.post(`/add_forum_entry/`, payload);
    return response.data;
}

// get Forum List
export const getForumList = async () => {
    const response = await Axios.get(`/user_forums/1`);
    return {
        data: response.data,
        success: response.status === 200,
    }
}

// get Forum data by id
export const getForumDetails = async (payload) => {
    const { forumId } = payload;
    const response = await Axios.get(`/forum_details/${forumId}`);
    return response.data;
}

// Add Comment
export const addCommentToForum = async (payload) => {
    const response = await Axios.post(`/add_comment/`, payload);
    return response.data;
}