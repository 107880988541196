import styles from "../styles/dialogue.module.css";
import History from "../components/History";
import Layout from '../components/Layout';

const HistoryWindow = () => {
  return (
    <Layout>
        <div className={styles.newDialog}>
        <History />     
        </div>
    </Layout>
  );
};

export default HistoryWindow;