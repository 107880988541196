import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { setDeleteGraphVisibility, setSelectedGraph } from 'slices/dashboard/reducer';
import { deleteChartFromDashboardThunk } from '../../slices/dashboard/thunk';
import {notifySuccess, notifyError} from "utils/customToasts";

const DeleteGraphModal = () => {

    const { deleteGraphVisibility, selectedGraph } = useSelector(state => state.DashboardReducer);
    const dispatch = useDispatch();

    const handleOnClose = () => {
        dispatch(setSelectedGraph(null));
        dispatch(setDeleteGraphVisibility(false));
    }

    const handleDelete = async () => {
        try {
            // Check for chart id
            if(!selectedGraph?.chartid){
                console.error("No chartid found for selected graph");
                return;
            }

            // create the delete payload
            const deletePayload = {
                chartid: selectedGraph?.chartid
            }

            // delete the chart from the dashboard
            dispatch(deleteChartFromDashboardThunk(deletePayload));

            notifySuccess("Chart deleted successfully");

            // close the delete model
            handleOnClose();
        } catch (error) {
            notifyError(error?.message || "Error while deleting chart");
            handleOnClose();
            return;
        }
    }

    return (
        <ConfirmDeleteModal
            open={deleteGraphVisibility}
            title="Delete Graph"
            content={`Are you sure you want to delete the graph?`}
            onConfirm={handleDelete}
            onCancel={handleOnClose}
        />
    )
}

export default DeleteGraphModal;