import { createSlice } from "@reduxjs/toolkit";
import {
    getUsersListThunk
} from "slices/users/thunk";

const initialState = {
    fetchUsers: true,
    users: [],
    errors: {}
}

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setUsers: (state, action) => {
            const payload = action.payload;
            state.users = payload
        },
        setFetchUsers: (state, action) => {
            const payload = action.payload;
            state.fetchUsers = payload;
        }
    },
    extraReducers: (builder) => {
        // Get users list
        builder.addCase(getUsersListThunk.fulfilled, (state, action) => {
            if (action.payload?.success) {
                state.users = action.payload.data;
            }
        });
        builder.addCase(getUsersListThunk.rejected, (state, action) => {
            state.errors.dashboards = action.payload || null;
        });
    }
});

export const {
    setUsers,
    setFetchUsers
} = usersSlice.actions;

export default usersSlice.reducer;