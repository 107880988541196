import styles from "../styles/dialogue.module.css";
import Chat from "../components/Chat"
import Layout from '../components/Layout';
import { useDashboardList } from 'hooks/dashboardHooks';
import CreateDashboardModal from 'components/Dashboard/CreateDashboradModal';

const ChatWindowAlt = () => {

  useDashboardList();

  return (
    <Layout>
      <div className={styles.newDialog}>
        <Chat />
      </div>

      <CreateDashboardModal />
    </Layout>
  );
};

export default ChatWindowAlt;