import { createAsyncThunk } from "@reduxjs/toolkit";
import { getForumList } from 'api/collaborativeForum/index';

export const getForumListThunk = createAsyncThunk(
    "getForumList",
    async () => {
        try {
            const response = await getForumList();
            return response.data;
        } catch (error) {
            return error;
        }
    }
)