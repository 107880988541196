import { useEffect, useState } from 'react';
import Modal from "components/Modal";
import Dropdown from "components/Dropdown";
import loadingGif from 'images/loader2.webp';
import SearchDropdown from "components/Dropdown/SearchDropdown";

const conditionOptions = [
    {
        label: "Above",
        value: ">",
    },
    {
        label: "Below",
        value: "<",
    },
    {
        label: "Equal",
        value: "=",
    },
]

const CreateAlert = ({
    loading = false,
    title = "Create Alert",
    showModal = false,
    onClose,
    onSubmit,
    formData = null,
    questions = [],
    ValidationSchema = null
}) => {

    const [selectedQuery, setSelectedQuery] = useState(formData?.query || null);
    const [selectedColumn, setSelectedColumn] = useState(formData?.column || null);
    const [selectedCondition, setSelectedCondition] = useState(formData?.condition || null);
    const [selectedEmail, setSelectedEmail] = useState(formData?.email || "");
    const [selectedThreshold, setSelectedThreshold] = useState(formData?.threshold || "");

    const [formErrors, setFormErrors] = useState({ errorCount: 0 });

    const onQueryChange = (data) => {
        setSelectedQuery(data);
        setSelectedColumn(null);
    }

    const verifyPayload = async (payload) => {
        if (!ValidationSchema) {
            return payload;
        }

        try {
            return ValidationSchema.validate(payload, { abortEarly: false });
        } catch (err) {
            console.log("Alert Validation Error: ", err);
            throw new Error(err)
        }
    }

    const handleSaveAlert = async () => {
        try {
            const payload = {
                chart_id: parseInt(selectedQuery?.value) || null,
                query_id: parseInt(selectedQuery?.value) || null,
                value_column: selectedColumn?.value,
                condition: selectedCondition?.value,
                threshold: selectedThreshold,
                email: selectedEmail,
            }

            const verifiedPayload = await verifyPayload(payload);
            return onSubmit(verifiedPayload);
        } catch (err) {
            let errorCount = 0
            const errObj = {};
            err.inner.forEach(error => {
                errObj[error.path] = error.message;
                errorCount += 1
            })
            setFormErrors({ ...errObj, errorCount });
        }
    }

    const handleClose = () => {
        // Reset Form
        setFormErrors({ errorCount: 0 });
        setSelectedQuery(null);
        setSelectedColumn(null);
        setSelectedCondition(null);
        setSelectedEmail("");
        setSelectedThreshold("");
        // trigger outer close function
        if (onClose) {
            onClose();
        }
    }

    useEffect(() => {
        setSelectedQuery(formData?.query);

        if (formData?.column) {
            setSelectedColumn({
                label: formData.column,
                value: formData.column,
            });
        }

        if (formData?.condition) {
            const condition = conditionOptions.find(condition => condition.value === formData?.condition);
            setSelectedCondition(condition);
        }

        if (formData?.threshold) {
            setSelectedThreshold(formData.threshold);
        }

        if (formData?.email) {
            setSelectedEmail(formData.email);
        }
    }, [formData])

    return (
        <Modal
            position="top-right"
            className="w-[40rem] px-8 py-10 bg-primary-3 text-white rounded-lg text-left"
            open={showModal}
        >
            <h1>{title}</h1>
            <hr className="mt-2" />

            {
                formErrors.errorCount > 0 && (
                    <div className="mt-6 py-3 px-2 rounded bg-error-1 flex items-center justify-center">
                        <span className="text-red-600">Please correct the errors</span>
                    </div>
                )
            }

            {
                selectedQuery?.label ? (
                    <p className="mt-6">Alert me when <b>"{selectedQuery?.label}"</b> graph raises to above, equal to or below specific value.</p>
                ) : (
                    <p className="mt-6">Please select a query for which you want to create an alert.</p>
                )
            }


            {/* Select Query */}
            <div className="w-full mt-6">
                <SearchDropdown disabled={formData?.query && !questions?.length} onChange={onQueryChange} selectedOption={selectedQuery} options={questions} />

                <span className="text-red-500 mt-2">{formErrors?.query_id}</span>
            </div>

            {/* Select columns for a selected query*/}
            <div className="mt-6 flex gap-6">
                <div className="w-full">
                    <Dropdown
                        hasError={formErrors?.value_column}
                        selectedOption={selectedColumn}
                        onChange={setSelectedColumn}
                        options={selectedQuery?.columns || []}
                    />
                    <span className="text-red-500 mt-2">{formErrors?.value_column}</span>
                </div>

            </div>

            {/* Alert Trigger */}
            <div className="mt-4 flex gap-6">

                <div className="w-full">
                    <Dropdown
                        hasError={formErrors?.condition}
                        selectedOption={selectedCondition}
                        onChange={setSelectedCondition}
                        options={conditionOptions}
                    />
                    <span className="text-red-500 mt-2">{formErrors?.condition}</span>
                </div>

                <div className="w-full">
                    <div className="relative w-full">
                        <input
                            type="text"
                            value={selectedThreshold}
                            onChange={(e) => setSelectedThreshold(e.target.value)}
                            className={`w-full p-3 rounded bg-[#0D1B2A] border ${formErrors?.threshold ? "border-red-500" : "border-[#382F5C]"}`}
                            placeholder="ThreshHold"
                        />
                    </div>
                    <span className="text-red-500 mt-2">{formErrors?.threshold}</span>
                </div>
            </div>

            <div className="mt-6">
                <label
                    htmlFor="email"
                    className={`block text-sm `}
                >
                    Email address
                </label>
                <input
                    type="email"
                    id="create-alert-email"
                    value={selectedEmail}
                    onChange={(e) => setSelectedEmail(e.target.value)}
                    className={`w-full mt-2 p-3 rounded bg-[#0D1B2A] border ${formErrors?.email ? "border-red-500" : "border-[#382F5C]"}`}
                    placeholder="Enter email id where you want to receive alerts."
                />
                <span className="text-red-500 mt-2">{formErrors?.email}</span>
            </div>

            <div className="flex justify-end mt-6 gap-4">
                <button disabled={loading} className="py-2 text-gray-300 px-4 border border-gray-200 rounded" onClick={handleClose}>Cancel</button>
                <button
                    disabled={loading}
                    className="py-2 bg-primary-1 px-4 rounded text-white flex gap-2 items-center disabled:bg-gray-600 disabled:text-gray-300"
                    onClick={handleSaveAlert}
                >
                    {
                        loading && (
                            <img src={loadingGif} alt="loading" className={`h-4 w-4`} />
                        )
                    }
                    <span>
                        {loading ? "Saving" : "Save"}
                    </span>
                </button>
            </div>
        </Modal>
    );
}

export default CreateAlert