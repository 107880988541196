import { useAlertsContext } from "components/Alerts/AlertsContext";
import {useAlertMethods} from 'hooks/alertHooks';

const ModalToggleButton = () => {

    const { alertsList } = useAlertMethods();
    const { setShowModal } = useAlertsContext();

    const visibility = alertsList?.length >= 1 ? "" : "hidden"

    return (
        <button
            onClick={() => setShowModal((prev) => !prev)}
            className={`bg-primary-1 text-sm text-white p-3 rounded-lg fixed bottom-4 right-4 ${visibility}`}
        >
            Create New Alert
        </button>
    );
}

export default ModalToggleButton;