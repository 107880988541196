import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFetchDashboardCharts, setFetchDashboards } from "slices/dashboard/reducer";
import { getDashboardsThunk } from "slices/dashboard/thunk";
import { getDashboardChartsThunk } from '../slices/dashboard/thunk';

export const useDashboardList = () => {
    // Local State
    const [loading, setLoading] = useState(false);

    // Redux State
    const dispatch = useDispatch();
    const { dashboards, fetchDashboards } = useSelector(state => state.DashboardReducer);

    // Fetch Dashboards
    const fetchDashboardList = async () => {
        try {
            await dispatch(getDashboardsThunk());
        } catch (error) {
            console.error("Error fetching dashboards:", error);
        } finally {
            await dispatch(setFetchDashboards(false));
            setLoading(false);
        }
    }

    useEffect(() => {
        if (fetchDashboards) {
            setLoading(true);
            fetchDashboardList();
        } else {
            setLoading(false);
        }
    }, [fetchDashboards]);

    // Return data
    return {
        dashboards,
        isLoading: loading,
        dashboardCount: dashboards.length,
    }
}

export const useDashboardCharts = () => {
    // Local State
    const [loading, setLoading] = useState(false);

    // Redux State
    const dispatch = useDispatch();
    const { dashboardCharts, fetchDashboardCharts } = useSelector(state => state.DashboardReducer);

    // Fetch Dashboard Charts
    const fetchDashboardChartsList = async (dashboardId) => {
        try {
            await dispatch(getDashboardChartsThunk());
            setLoading(false);
        } catch (error) {
            console.error("Error fetching dashboard charts:", error);
            setLoading(false);
        } finally {
            await dispatch(setFetchDashboardCharts(false));   
        }
    }

    useEffect(() => {
        if (fetchDashboardCharts) {
            setLoading(true);
            fetchDashboardChartsList(fetchDashboardCharts.dashboardId);
        } else {
            setLoading(false);
        }
    }, [fetchDashboardCharts]);

    // Return data
    return {
        dashboardCharts,
        isLoading: loading,
    }
}