import styles from "styles/dialogue.module.css";
import React, { useState, useEffect } from 'react';
import ForgetPasswordComponent from "components/auth/ForgetPassword";

const ForgetPasswordPage = () => { 
  useEffect(() => {
  }, []);

  return (
    <div className={styles.newDialog}>
      <ForgetPasswordComponent />     
    </div>
  );
};

export default ForgetPasswordPage;
