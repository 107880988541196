import styles from "styles/dialogue.module.css";
import Layout from 'components/Layout';
import ChatHistory from "components/ChatHistory";
import { useDashboardList } from 'hooks/dashboardHooks';
import CreateDashboardModal from 'components/Dashboard/CreateDashboradModal';

const HistoryChatWindow = () => {

  useDashboardList();

  return (
    <Layout>
      <div className={styles.newDialog}>
        <ChatHistory />
      </div>

      <CreateDashboardModal />
    </Layout>
  );
};

export default HistoryChatWindow;