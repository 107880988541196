// AlertsContext.js
import { createContext, useContext } from "react";

export const AlertsContext = createContext();

export const useAlertsContext = () => {
    const context = useContext(AlertsContext);
    if (!context) {
        throw new Error("useAlertsContext must be used within an AlertsProvider");
    }
    return context;
};
