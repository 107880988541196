import ForgetPasswordPage from 'pages/auth/forgetPasswordPage';
import LoginPage from 'pages/loginPage';
import ResetPasswordPage from 'pages/auth/resetPasswordPage';

const publicRoutes = [
    {
        path: "/",
        name: "Login | Register",
        element: <LoginPage />,
    },
    {
        path: "/forget-password",
        name: "Forget Password",
        element: <ForgetPasswordPage />,
    },
    {
        path: "/reset-password",
        name: "Reset Password",
        element: <ResetPasswordPage />,
    },
]

export default publicRoutes;