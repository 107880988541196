// src/components/ThoughtProvokingQuestions.js
import React from 'react';
import styles from '../styles/thoughtProvokingQuestions.module.css';
import DOMPurify from 'dompurify';

const ThoughtProvokingQuestions = ({ question }) => {
  if (!question || question.trim() === '') return null;

  return (
    <div className={styles.thoughtProvokingQuestionsContainer}>
        <h4 style={{ color: '#fff', margin: '8px 0px' }}>
          <b>Recommended Actions </b>
        </h4>
        <div className={styles.thoughtProvokingQuestionBox}>
            {/* {question} */}
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }} />
        </div>
    </div>
  );
};

export default ThoughtProvokingQuestions;
