import { createSlice } from "@reduxjs/toolkit";
import { getForumListThunk } from "slices/collabration/thunk";

const initialState = {
    selectedGraph: null,
    shareCollabrationVisibility: false,
    collabrationList: [],
    errors: {}
}

const collabrationSlice = createSlice({
    name: "collabration",
    initialState,
    reducers: {
        setCollabrationList: (state, action) => {
            const payload = action.payload;
            state.collabrationList = payload
        },

        addCollabration: (state, action) => {
            const payload = action.payload;
            state.collabrationList = [...state.collabrationList, payload]
        },

        setShareCollabrationVisibility: (state, action) => {
            const payload = action.payload;
            state.shareCollabrationVisibility = payload
        },

        setSelectedGraph: (state, action) => {
            const payload = action.payload;
            state.selectedGraph = payload
        }
    },
    extraReducers: (builder) => {
        // Get users list
        builder.addCase(getForumListThunk.fulfilled, (state, action) => {
            state.collabrationList = action?.payload?.data || [];
        });
        builder.addCase(getForumListThunk.rejected, (state, action) => {
            state.errors.collabrationList = action.payload || null;
        });
    }
});

export const {
    setCollabrationList,
    addCollabration,
    setShareCollabrationVisibility,
    setSelectedGraph,
} = collabrationSlice.actions;

export default collabrationSlice.reducer;