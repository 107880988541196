import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from 'images/main-logo.svg';
import eyeOpen from 'images/eye.svg';
import eyeClosed from 'images/eye-closed.svg';
import { useAuth } from 'context/AuthContext';
import { LoginUserSchema, RegisterUserSchema } from 'validations/auth';
import loadingGif from 'images/loader2.webp';
import {notifySuccess, notifyError} from "utils/customToasts";

const LoginComponent = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState("");
  const [company, setCompany] = useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [isFormAnimating, setIsFormAnimating] = useState(false);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const { login, registerUser } = useAuth();
  const navigate = useNavigate();

  const isFormValid = () => {
    if (isSignUp) {
      return name && company && email && password;

    } else {
      return email && password;
    }
  };

  const validateForm = async () => {
    try {
      if (isSignUp) {
        const payload = {
          email: email,
          full_name: name,
          company_name: company,
          password: password,
        }
        const result = await RegisterUserSchema.validate(payload, { abortEarly: false });
        return result;
      } else {
        const payload = {
          email: email,
          password: password,
        }
        const result = await LoginUserSchema.validate(payload, { abortEarly: false });
        return result;
      }
    } catch (err) {
      const errObj = {};
      err.inner.forEach(error => {
        errObj[error.path] = error.message;
      })
      setErrors({ ...errObj, errorCount: Object.keys(errObj).length });
      return null;
    }
  };

  const handleToggleForm = () => {
    setIsFormAnimating(true);
    setTimeout(() => {
      setIsSignUp(!isSignUp);
      setEmail('');
      setPassword('');
      setName('');
      setIsFormAnimating(false);
    }, 300);
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = await validateForm();

      if (data === null) {
        setLoading(false);
        return;
      }

      setErrors(null);

      // For Signup
      if (isSignUp) {
        const response = await registerUser(data);
        console.log("Signup Response => ", response);
        setLoading(false);
        notifySuccess("Registration successful");
        return navigate('/chat');
      }

      // For Login
      const response = await login(data);
      console.log("Login Response => ", response);
      setLoading(false);
      notifySuccess("Login successful");
      return navigate('/chat');
    } catch (error) {
      console.log("errors => ", error);

      if (error?.response?.data?.error?.message) {
        setErrors({ formError: error?.response?.data?.error?.message });
      } else {
        notifyError(error?.message || "Something went wrong");
      }
      setLoading(false);
    }

  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#0D1B2A]">
      <div className="bg-[#0B1723] text-white p-8 rounded-lg w-full max-w-md">
        <img src={logo} alt="Storymetrix Logo" className="h-16 mx-auto mb-8" />
        <h2 className={`text-2xl font-bold text-center mb-4 transition-opacity duration-300 ease-out ${isFormAnimating ? 'opacity-0' : 'opacity-100'}`}>
          {isSignUp ? 'Sign Up' : 'Sign In'}
        </h2>

        <p className={`text-center mb-6 transition-opacity duration-300 ease-out ${isFormAnimating ? 'opacity-0' : 'opacity-100'}`}>
          Please enter your details to {isSignUp ? 'Sign Up' : 'Sign In'}
        </p>

        {
          errors?.formError && (
            <div className="my-4 py-2 px-2 rounded bg-error-1 flex items-center justify-center">
              <span className="text-red-600">{errors?.formError}</span>
            </div>
          )
        }

        <div className={`transition-opacity duration-300 ease-out ${isFormAnimating ? 'opacity-0' : 'opacity-100'}`}>

          {isSignUp && (
            <div className="mb-4">
              <label htmlFor="name" className="block mb-2 text-sm">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-3 rounded bg-[#0D1B2A] border border-[#382F5C]"
                placeholder="Enter your name"
              />
              <span className="text-red-500 mt-2">{errors?.full_name}</span>
            </div>
          )}

          {isSignUp && (
            <div className="mb-4">
              <label htmlFor="name" className="block mb-2 text-sm">Company Name</label>
              <input
                type="text"
                id="company-name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                className="w-full p-3 rounded bg-[#0D1B2A] border border-[#382F5C]"
                placeholder="Enter company name"
              />
              <span className="text-red-500 mt-2">{errors?.company_name}</span>
            </div>
          )}

          <div className="mb-4">
            <label htmlFor="email" className="block mb-2 text-sm">Email address</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 rounded bg-[#0D1B2A] border border-[#382F5C]"
              placeholder="Enter your Email Id"
            />
            <span className="text-red-500 mt-2">{errors?.email}</span>
          </div>

          <div className="mb-4 relative">
            <label htmlFor="password" className="block mb-2 text-sm">Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 rounded bg-[#0D1B2A] border border-[#382F5C]"
              placeholder="Enter your Password"
            />
            <img
              src={showPassword ? eyeOpen : eyeClosed}
              alt="Toggle visibility"
              className="absolute h-5 right-3 top-10 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            />

            <span className="text-red-500 mt-2">{errors?.password}</span>
          </div>
          {
            !isSignUp && (
              <div className="text-right">
                <Link to="/forget-password" className="text-purple-500 cursor-pointer" onClick={handleToggleForm}>Forget Password</Link>
              </div>
            )
          }
          <button
            className={`flex justify-center items-center gap-3 w-full p-3 mt-4 rounded transform transition-transform duration-300 ease-out ${loading ? 'scale-95' : ''} bg-purple-700 hover:bg-purple-800  disabled:bg-gray-600 disabled:cursor-not-allowed`}
            disabled={!isFormValid() || loading}
            onClick={handleButtonClick}
          >
            {
              loading && (
                <img src={loadingGif} alt="loading" className={`h-6 w-6`} />
              )
            }
            {isSignUp ? 'SIGN UP' : 'SIGN IN'}
          </button>
        </div>
        <div className={`text-center mt-4 transition-opacity duration-300 ease-out ${isFormAnimating ? 'opacity-0' : 'opacity-100'}`}>
          {isSignUp ? (
            <>
              Already have an account?{' '}
              <span className="text-purple-500 cursor-pointer" onClick={handleToggleForm}>Sign In</span>
            </>
          ) : (
            <>
              Don’t have an account?{' '}
              <span className="text-purple-500 cursor-pointer" onClick={handleToggleForm}>Sign Up</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;