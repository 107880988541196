import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getForumListThunk } from "slices/collabration/thunk";
import {getAvatarBGColor} from 'utils/helper';

// Images 
import HistoryThumbnail from "images/history/history-thumbnail.png";
import clsx from "clsx";


const CollabrationForumCard = ({ item }) => {

    // generate redirection uri
    const getForumDetailLink = (id) => {
        return `/collaborative-forum-detail?forum_id=${id}`;
    }

    // Shared Users
    const sharedUsers = useMemo(() => {
        const users = (item?.shared_users ?? []).map((user) => ({
            title: user?.name || "User",
            name: user?.name?.[0]?.toUpperCase() || "U",
            user_id: user.user_id,
            color: getAvatarBGColor(user.user_id),
        }));
    
        if (users.length > 3) {
            const remainingUsersCount = users.length - 2;
            const additionalUser = {
                title: `${remainingUsersCount} more users`,
                name: `+${remainingUsersCount}`,
                user_id: null,
                color: "bg-avatar",
            };
            return [...users.slice(0, 2), additionalUser];
        }
    
        return users;
    }, [item?.shared_users]);
    
    return (
        <Link
            to={getForumDetailLink(item.forum_id)}
            className="bg-primary-3 p-4 rounded-lg relative flex flex-col items-center row-gap-4"
        >
            <img src={HistoryThumbnail} alt={item.question} className="w-full h-40 object-cover rounded-lg" />

            <div className="w-full gap-1 mt-2">
                {/* Content */}
                <h3 className="text-white text-lg line-clamp-1">{item.question}</h3>
                <div className="flex justify-between items-center">
                    <p className="text-gray-400 mt-[6px]">Added on {new Date(item?.created_at).toLocaleDateString()}</p>

                    <div className="flex -space-x-1 overflow-hidden">
                        {
                            sharedUsers.map((user) => {
                                return (
                                    <div title={user.title} className={clsx('h-6 w-6 flex items-center justify-center rounded-full', user.color)}>
                                        <span className="text-black">{user.name}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </Link>
    )
}

const CollabrativeForumListing = () => {

    const dispatch = useDispatch();
    const { collabrationList } = useSelector(state => state.CollabrationReducer);

    const sortedCollabrationList = collabrationList.toSorted((a, b) => b.forum_id - a.forum_id);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch data from API
    const fetchForumsList = async () => {
        try {
            await dispatch(getForumListThunk());
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchForumsList();
    }, []);

    if (loading) {
        return <div className="text-white text-center mt-10">Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500 text-center mt-10">Error: {error.message}</div>;
    }

    if (sortedCollabrationList.length === 0) {
        return <div className="text-white text-center mt-10">No data available</div>;
    }

    return (
        <div className="p-10">

            {/* <h1 className="py-4 px-3 border-b border-[#BEEF9E66] font-medium text-2xl">Threads</h1> */}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {sortedCollabrationList.map(item => (
                    <CollabrationForumCard key={item.forum_id} item={item} />
                ))}
            </div>
        </div>
    );
};

export default CollabrativeForumListing;
