import Modal from "components/Modal"
import { useEffect, useState } from "react";

// Images
import CrossIcon from "images/cross-icon.svg";
import ArrowLeftIcon from "images/arrow-left-icon.svg";
import ReasonSelection from "components/Downvote/ReasonSelection";
import ReasonForm from "components/Downvote/ReasonForm";
import loadingGif from 'images/loader2.webp';
import { DownvoteSchema } from '../../validations/chat';
import { downvoteMessage } from '../../api/chat/index';
import {notifySuccess, notifyError} from "utils/customToasts";

const Downvote = ({ open, queryId, onClose }) => {
    // Status States
    const [loading, setLoading] = useState(false)
    // form States
    const [reason, setReason] = useState("");
    const [options, setOptions] = useState([]);
    const [comment, setComment] = useState("");
    const [errors, setErrors] = useState({ errorCount: 0 });

    // Validate form payload
    const validatePayload = async (payload) => {
        try {
            return DownvoteSchema.validate(payload, { abortEarly: false });
        } catch (err) {
            throw new Error(err);
        }
    }

    // Handle the option Select
    const onOptionSelect = (status, value) => {
        setOptions(prev => {
            const currentOptions = [...prev];

            if (status) {
                currentOptions.push(value);
            } else {
                currentOptions.splice(currentOptions.indexOf(value), 1);
            }

            return currentOptions;
        })
    }

    const handleCommentChange = (event) => {
        const value = event.target.value;
        setComment(value);
    }

    const submitReport = async () => {
        setLoading(true)
        try {
            const payload = {
                query_id: queryId,
                reason,
                options,
                comment,
            }

            const validatedPayload = await validatePayload(payload);

            if (validatedPayload === null) {
                throw new Error("Invalid Payload");
            }

            const response = await downvoteMessage(validatedPayload);

            if (!response?.success) {
                throw new Error(response?.message || "Something went wrong while");
            }

            notifySuccess(response?.message);
            setErrors({ errorCount: 0 });
            setLoading(false);
            onClose();
        } catch (err) {

            setLoading(false);

            const errObj = {};
            console.log("Inner Errors", err)
            err?.inner?.forEach(error => {
                errObj[error.path] = error.message;
            });

            const errorCount = Object.keys(errObj).length;
            setErrors({ ...errObj, errorCount });

            if (!errorCount || errObj?.query_id) {
                notifyError(`Error: ${err?.message || "Something went wrong"}`);
            }
            console.error("Error submitting downvote report: ", err);
        }
    }

    useEffect(() => {
        if (!open) {
            setLoading(false);
            setReason("");
            setOptions([]);
            setComment("");
        }
    }, [open]);

    return (
        <Modal
            open={open}
            className="relative mx-auto max-w-[42rem] w-full px-8 py-10 bg-primary-3 text-white rounded-lg"
        >
            <img
                onClick={onClose}
                className="absolute cursor-pointer h-6 w-6 -top-3 -right-3"
                alt={"Close Forecast"}
                src={CrossIcon}
            />
            {/* Header */}
            <h3 className="flex items-center gap-4 text-xl">
                {
                    reason && (
                        <img
                            onClick={() => {
                                setReason("");
                                setOptions([]);
                            }}
                            className="cursor-pointer h-6 w-6"
                            alt={"Back"}
                            src={ArrowLeftIcon}
                        />
                    )
                }
                <span>{reason || "Select Reason"}</span>
            </h3>
            <hr className="mt-2 border border-gray-400" />

            {/* Reason Selection */}
            {
                reason === "" && <ReasonSelection onSelect={(val) => setReason(val)} />
            }

            {/* Feedback Form */}

            {
                reason && (
                    <>
                        <ReasonForm
                            reason={reason}
                            selectedOptions={options}
                            onOptionSelect={onOptionSelect}
                            onCommentChange={handleCommentChange}
                            errors={errors}
                        />

                        <div className="flex justify-end mt-6 gap-4">
                            <button
                                disabled={loading}
                                className="py-2 px-4 border border-gray-200 disabled:border-gray-300 rounded text-gray-300 disabled:text-gray-300"
                                onClick={onClose}
                            >
                                Cancel
                            </button>

                            <button
                                disabled={loading}
                                className="py-2 bg-primary-1 px-4 rounded text-white flex gap-2 items-center disabled:bg-gray-600 disabled:text-gray-300"
                                onClick={submitReport}
                            >
                                {
                                    loading && (
                                        <img src={loadingGif} alt="loading" className={`h-4 w-4`} />
                                    )
                                }
                                <span>
                                    {loading ? "Submiting" : "Submit"}
                                </span>
                            </button>
                        </div>
                    </>
                )
            }
        </Modal>
    )
}

export default Downvote;