import Axios from "api/Axios";

export const createAlert = async (payload) => {
    const { chart_id, query_id, ...data } = payload;
    const response = await Axios.post(`/alerts/`, data, {
        params: {
            chart_id,
            query_id
        }
    });
    return response.data;
}

export const updateAlert = async (payload) => {
    const response = await Axios.put(`/update_alert/`, payload);
    return response.data;
}

export const getAllAlerts = async (payload) => {
    const response = await Axios.get(`/get_all_alerts/`);
    return response.data;
}

export const deleteAlertById = async (payload) => {
    const response = await Axios.delete(`/delete_alert/`, {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        data: payload
    });
    return response.data;
}

export const getAlertById = async (payload) => {
    const response = await Axios.get(`/alert details by id/`, {
        params: payload
    });
    return response.data;
}