import styles from "styles/dialogue.module.css";
import Layout from 'components/Layout';
import CollabrativeForumDetail from "components/Collabration/CollabrativeForumDetail";

const CollaborativeForumDetailWindow = () => {

    return (
        <Layout>
            <div className={styles.newDialog}>
                <CollabrativeForumDetail />
            </div>
        </Layout>
    );
};

export default CollaborativeForumDetailWindow;