import Select, { components } from "react-select";
import { useUsersList } from 'hooks/userHooks';
import { useMemo } from "react";

// Images
import AddIcon from "images/add-icon.svg";
import CrossIcon from "images/cross-icon.svg";
import PeopleIcon from "images/people-icon.svg";

const selectStyles = {
    control: (provided) => ({
        ...provided,
        cursor: "pointer",
        backgroundColor: "#0B1723",
        color: "white",
        border: "1px solid #382F5C",
        padding: "6px 12px 6px 2px",
        outline: "none",

        ":hover": {
            border: "1px solid #666699",
            outline: "none",
        },

        ":focus": {
            border: "1px solid #666699",
            outline: "none",
        },

        ":active": {
            border: "1px solid #666699",
            outline: "none",
        },

    }),
    menu: (provided) => ({
        ...provided,
        color: "white",
        borderRadius: 10,
        overflow: "hidden",
        boxShadow: "0px 0px 4px 0px #7357EB40",
        backgroundColor: "#0B1723",
    }),
    option: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#0B1723",
        ":hover": {
            backgroundColor: "#132639",
        }
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "white",
    }),
    multiValue: (baseStyles) => ({
        ...baseStyles,
        alignItems: "center",
        gap: "4px",
        backgroundColor: "#0D1B2A",
        color: "#ffffff",
        border: "1px solid #382F5C",
        borderRadius: "4px",
        padding: "6px"
    }),
    multiValueLabel: () => ({
        color: "#ffffff",
    }),
    multiValueRemove: () => ({}),
    input: (baseStyles) => ({
        ...baseStyles,
        color: "white",
    })
};

const MultiValueRemove = (props) => {
    return (
        <components.MultiValueRemove {...props} >
            <img className="w-4 h-4" src={CrossIcon} alt="Remove" />
        </components.MultiValueRemove>
    )
}

const MenuList = (props) => {
    const { isLoading } = props.selectProps;
    return (
        <components.MenuList  {...props}>
            {
                isLoading ? (
                    <div className="p-4 flex items-center">
                        <span>Loading...</span>
                    </div>
                ) : (
                    props.children
                )
            }
        </components.MenuList>
    );
};

const Option = (props) => {

    if (props.selectProps.isLoading) {
        return (
            <div className="flex flex-col grow text-xs">
                <span>Loading...</span>
            </div>
        )
    }

    return (
        <components.Option {...props}>
            <div className="flex items-center gap-2">

                <div className="w-8 h-8 rounded-full flex items-center justify-center bg-gray-500 text-white">
                    {props?.label?.[0] || "U"}
                </div>

                <div className="flex flex-col grow text-xs">
                    <span>{props?.data?.label || "User"}</span>
                    <span className="text-[#B7B7B7]">{props?.data?.email || "User"}</span>
                </div>

                <img className="w-4 h-4" src={AddIcon} alt="Add" />

            </div>
        </components.Option>
    )
}

const UserSelect = ({
    selectedOption,
    onChange,
    disabled
}) => {

    const { users = [], isLoading } = useUsersList();

    const options = useMemo(() => {
        return users.map(x => {
            return {
                ...x,
                label: x.name,
                value: x.user_id,
            }
        });
    }, [users]);

    return (
        <Select
            isMulti
            isLoading={isLoading}
            disabled={disabled}
            options={options}
            value={selectedOption}
            onChange={(newValue) => {
                onChange(newValue);
            }}
            backspaceRemovesValue={false}
            components={{
                Option,
                MenuList,
                MultiValueRemove,
                IndicatorSeparator: null,
                DropdownIndicator: () => <img src={PeopleIcon} alt="Select" />,
            }}
            isClearable={false}
            menuPosition={"absolute"}
            menuPlacement={"auto"}
            styles={selectStyles}
            tabSelectsValue={false}
        />
    );
};

export default UserSelect;
